import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import axios from 'axios';
import { TASKS_URL } from '../../constants/Urls';
import { useTranslation } from 'react-i18next';

const TasksPieChart = ({ event }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const colors = ['#33cc99', '#AB8BFC', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${TASKS_URL}?id=${event.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const tasks = response.data.tasks || [];
        const filteredTasks = tasks.filter(task => task.type === 'task');
        const statusCounts = filteredTasks.reduce((acc, task) => {
          acc[task.status] = (acc[task.status] || 0) + 1;
          return acc;
        }, {});

        const totalTasks = filteredTasks.length;

        const chartData = Object.keys(statusCounts).map((status, index) => ({
          name: t(status),
          value: statusCounts[status],
          color: colors[index % colors.length],
          percent: Math.round((statusCounts[status] / totalTasks) * 100) + '%',
        }));

        setData(chartData);
      } catch (error) {
        console.error('Error fetching task data', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [event, t]);

  if (isLoading) {
    return <div>{t('Loading...')}</div>;
  }

  if (data.length === 0 || data.every(item => item.value === 0)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>{t('Tasks')}</h3>
        <div style={{ marginTop: '10px' }}>
          {t('No tasks added')}
        </div>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ textAlign: 'center' }}>{t('Tasks')}</h3>
      <PieChart width={400} height={300} style={{ cursor: 'pointer' }}>
        <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <ul>
        {data.map((entry, index) => (
          <li key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.value} ({entry.percent})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TasksPieChart;
