import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Select from 'react-select';
import { RESPONSES_URL, GUESTS_URL } from '../../constants/Urls';
import './Guests.css';
import GuestModal from './GuestModal';
import CreateGuestModal from './CreateGuestModal';

const Guests = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State variables
  const [guests, setGuests] = useState([]);
  const [guestsInfo, setGuestsInfo] = useState({ guests_count: 0, max_guests: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [showCreateGuestModal, setShowCreateGuestModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [attendanceFilter, setAttendanceFilter] = useState('3'); // default to 'Show All'
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isEditMode, setIsEditMode] = useState(false);

  const attendanceOptions = [
    { value: '1', label: t('Attended') },
    { value: '0', label: t('Not Attended') },
    { value: '3', label: t('Show All') },
  ];

  const sortByOptions = [
    { value: 'name', label: t('Name') },
    { value: 'created_at', label: t('Registration Date') },
  ];

  const sortOrderOptions = [
    { value: 'asc', label: t('Ascending') },
    { value: 'desc', label: t('Descending') },
  ];

  useEffect(() => {
    loadGuests();
    getGuestsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, attendanceFilter, sortBy, sortOrder]);

  // Use a debounced effect for the searchTerm
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadGuests();
    }, 500); // Adjust the debounce delay as needed

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const loadGuests = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const params = {
        event_id: id,
        name: searchTerm || undefined,
        is_attended: attendanceFilter === '3' ? undefined : attendanceFilter,
        sort_by: sortBy,
        sort_order: sortOrder,
      };

      const response = await axios.get(`${RESPONSES_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      if (response.data) {
        const allPeople = flattenGuestsAndCompanions(response.data.responses);
        setGuests(allPeople);
      }
    } catch (error) {
      console.error('Error fetching guests:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setIsLoading(false);
    }
  }, [id, searchTerm, attendanceFilter, sortBy, sortOrder, t]);

  const getGuestsCount = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${GUESTS_URL}/count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { event_id: id },
      });
      setGuestsInfo(response.data);
    } catch (error) {
      console.error('Error fetching guests count:', error);
      alert(t('Error') + ': ' + error.message);
    }
  }, [id, t]);

  const flattenGuestsAndCompanions = (responses) => {
    const flattened = [];
    responses.forEach((guest) => {
      flattened.push(guest);
      if (guest.companions && guest.companions.length > 0) {
        guest.companions.forEach((companion) => {
          flattened.push({
            ...companion,
            parentName: guest.name,
            parentEmail: guest.email,
            isCompanion: true,
          });
        });
      }
    });
    return flattened;
  };

  const handleAttendanceChange = async (person) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${GUESTS_URL}/attended`,
        {
          participant_id: person.participant_id,
          is_attended: !person.is_attended,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setGuests((prevGuests) =>
          prevGuests.map((p) =>
            p.participant_id === person.participant_id
              ? { ...p, is_attended: !p.is_attended }
              : p
          )
        );
      } else {
        alert(t('Error') + ': ' + t('Failed to update attendance status.'));
      }
    } catch (error) {
      alert(t('Error') + ': ' + t('An error occurred while updating attendance.'));
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleViewGuest = (guest) => {
    setSelectedGuest(guest);
    setShowGuestModal(true);
  };

  const handleEditGuest = (guest) => {
    setEditingGuest(guest);
    setShowCreateGuestModal(true);
  };

  const handleAddGuest = () => {
    setEditingGuest(null);
    setShowCreateGuestModal(true);
  };

  const handleCloseGuestModal = () => {
    setShowGuestModal(false);
    setSelectedGuest(null);
    loadGuests(); // Reload guests after closing modal
  };

  const handleCloseCreateGuestModal = () => {
    setShowCreateGuestModal(false);
    setEditingGuest(null);
    loadGuests(); // Reload guests after closing modal
  };

  return (
    <div className="guests-container">
      <h2>{t('Guests')}</h2>
      <p>
        {t('totalRegistrations', {
          count: `${guestsInfo.guests_count || 0}/${guestsInfo.max_guests || 0}`,
        })}
      </p>

      <div className="search-container">
        <input
          type="text"
          placeholder={t('Search by name')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <Select
          className="filter-select"
          options={attendanceOptions}
          value={attendanceOptions.find((option) => option.value === attendanceFilter)}
          onChange={(selectedOption) => setAttendanceFilter(selectedOption.value)}
          placeholder={t('Attendance')}
        />
        <Select
          className="filter-select"
          options={sortByOptions}
          value={sortByOptions.find((option) => option.value === sortBy)}
          onChange={(selectedOption) => setSortBy(selectedOption.value)}
          placeholder={t('Sort By')}
        />
        <Select
          className="filter-select"
          options={sortOrderOptions}
          value={sortOrderOptions.find((option) => option.value === sortOrder)}
          onChange={(selectedOption) => setSortOrder(selectedOption.value)}
          placeholder={t('Order')}
        />
      </div>

      <button
        className={`mark-attended-button ${isEditMode ? 'active' : ''}`}
        onClick={toggleEditMode}
      >
        {isEditMode ? t('Exit Attendance Mode') : t('Mark Attended')}
      </button>

      <div className="guests-list">
        {isLoading ? (
          <div className="loader">{t('Loading...')}</div>
        ) : guests.length === 0 ? (
          <div>{t('No people invited.')}</div>
        ) : (
          guests.map((guest) => {
            const nameResponse = guest.name || 'Unnamed';
            const isCompanion = guest.isCompanion || false;
            const parentName = isCompanion ? guest.parentName : null;

            return (
              <div key={guest.participant_id} className="guest-item">
                <div className="guest-item-details">
                  {isEditMode ? (
                    <input
                      type="checkbox"
                      checked={guest.is_attended}
                      onChange={() => handleAttendanceChange(guest)}
                    />
                  ) : guest.is_attended ? (
                    <i className="fas fa-check attended-icon"></i>
                  ) : (
                    <div className="empty-icon-space"></div>
                  )}
                  <span>
                    {isCompanion && guest.type === 'child'
                      ? `${nameResponse} (${t('Child with')} ${parentName})`
                      : isCompanion
                      ? `${nameResponse} (${t('Companion of')} ${parentName})`
                      : nameResponse}
                  </span>
                </div>
                <div className="guest-item-actions">
                  <button
                    className="guest-item-button"
                    onClick={() => handleViewGuest(guest)}
                  >
                    {t('View')}
                  </button>
                  <button
                    className="guest-item-button"
                    onClick={() => handleEditGuest(guest)}
                  >
                    {t('Edit')}
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>

      <button className="invite-button" onClick={handleAddGuest}>
        {t('Add Guest')}
      </button>

      {showGuestModal && (
        <GuestModal
          showModal={showGuestModal}
          onClose={handleCloseGuestModal}
          guest={selectedGuest}
          eventId={id}
        />
      )}
      {showCreateGuestModal && (
        <CreateGuestModal
          showModal={showCreateGuestModal}
          onClose={handleCloseCreateGuestModal}
          eventId={id}
          guest={editingGuest}
          responses={editingGuest?.responses || []}
        />
      )}
    </div>
  );
};

export default Guests;
