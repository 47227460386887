import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NotFoundPage.css';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const errorMessage = location.state?.message || t('Page not found.');

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">{t('404 - Not Found')}</h1>
      <p className="not-found-message">{t(errorMessage)}</p>
      <Link to="/" className="back-home-link">{t('Go back to Home')}</Link>
    </div>
  );
};

export default NotFoundPage;
