import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { CONTACT_POINTS_URL } from '../../constants/Urls';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './ContactPointsPage.css';

const ContactPointsPage = () => {
  const { id: eventId } = useParams();
  const [contactPoints, setContactPoints] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tempContact, setTempContact] = useState({
    contact_name: '',
    contact_role: '',
    phone: '',
    email: '',
    id: null,
  });
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchContactPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const fetchContactPoints = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${CONTACT_POINTS_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
        },
      });

      if (response.data.success) {
        const sortedPoints = response.data.contact_points.sort(
          (a, b) => a.order_id - b.order_id
        );
        setContactPoints(sortedPoints);
      } else {
        alert(t('Failed to fetch contact points.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }     
    }
  };

  const handleSaveContact = async () => {
    if (
      !tempContact.contact_name ||
      !tempContact.contact_role ||
      !tempContact.phone ||
      !tempContact.email
    ) {
      alert(t('Please fill in all fields.'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const url = CONTACT_POINTS_URL;
      const method = 'POST';

      const payload = {
        event_id: eventId,
        contact_name: tempContact.contact_name,
        contact_role: tempContact.contact_role,
        phone: tempContact.phone,
        email: tempContact.email,
      };

      if (editMode) {
        payload.id = tempContact.id;
      }

      const response = await axios({
        method: method,
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      if (response.data.success) {
        setShowModal(false);
        fetchContactPoints();
        alert(t('Contact point saved successfully.'));
      } else {
        alert(t('Failed to save contact point.'));
      }
    } catch (error) {
      alert(t('Failed to save contact point.'));
    }
  };

  const handleEditContact = (contact) => {
    setTempContact(contact);
    setEditMode(true);
    setShowModal(true);
  };

  const handleDeleteContact = (contactId) => {
    if (window.confirm(t('Are you sure you want to delete this contact point?'))) {
      deleteContactPoint(contactId);
    }
  };

  const deleteContactPoint = async (contactId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${CONTACT_POINTS_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: contactId,
        },
      });

      if (response.data.success) {
        fetchContactPoints();
        alert(t('Contact point deleted successfully.'));
      } else {
        alert(t('Failed to delete contact point.'));
      }
    } catch (error) {
      alert(t('Failed to delete contact point.'));
    }
  };

  const handleAddContact = () => {
    setTempContact({
      contact_name: '',
      contact_role: '',
      phone: '',
      email: '',
      id: null,
    });
    setEditMode(false);
    setShowModal(true);
  };

  const updateContactOrders = async (newOrder) => {
    const promises = newOrder.map(async (item, index) => {
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${CONTACT_POINTS_URL}/order`,
          {
            id: item.id,
            order_id: index,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        alert(t('Failed to update contact point order.'));
      }
    });

    await Promise.all(promises);
    fetchContactPoints();
  };

  // Implement drag-and-drop functionality using react-beautiful-dnd
  // or similar library if needed.

  return (
    <div className="contact-points-page">
      <Button variant="primary" onClick={handleAddContact}>
        {t('Add Contact')}
      </Button>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Role')}</th>
            <th>{t('Phone')}</th>
            <th>{t('Email')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {contactPoints.map((item, index) => (
            <tr key={item.id}>
              <td>{item.contact_name}</td>
              <td>{item.contact_role}</td>
              <td>{item.phone}</td>
              <td>{item.email}</td>
              <td>
                <Button variant="link" onClick={() => handleEditContact(item)}>
                  <i className="fas fa-edit"></i>
                </Button>
                <Button variant="link" onClick={() => handleDeleteContact(item.id)}>
                  <i className="fas fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for adding/editing contact */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? t('Edit Contact') : t('Add Contact')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="contactName">
              <Form.Label>{t('Contact Name')}</Form.Label>
              <Form.Control
                type="text"
                value={tempContact.contact_name}
                onChange={(e) =>
                  setTempContact({ ...tempContact, contact_name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="contactRole">
              <Form.Label>{t('Contact Role')}</Form.Label>
              <Form.Control
                type="text"
                value={tempContact.contact_role}
                onChange={(e) =>
                  setTempContact({ ...tempContact, contact_role: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="contactPhone">
              <Form.Label>{t('Phone')}</Form.Label>
              <Form.Control
                type="text"
                value={tempContact.phone}
                onChange={(e) =>
                  setTempContact({ ...tempContact, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="contactEmail">
              <Form.Label>{t('Email')}</Form.Label>
              <Form.Control
                type="email"
                value={tempContact.email}
                onChange={(e) =>
                  setTempContact({ ...tempContact, email: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveContact}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactPointsPage;
