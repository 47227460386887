import React, { useState, useEffect } from 'react';
import { Table, Button, Image, Spinner, Form } from 'react-bootstrap';
import axios from 'axios';
import { TEAM_URL } from '../../constants/Urls';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './TeamMembersPage.css';

const TeamMembersPage = () => {
  const { id: eventId } = useParams();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTeamMembers();
  }, [eventId]);

  const fetchTeamMembers = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${TEAM_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: eventId,
        },
      });

      if (response.data && response.data.team_members) {
        setTeamMembers(response.data.team_members);
      } else {
        alert(t('Failed to fetch team members.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }  
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMemberActive = async (member) => {
    const newStatus = member.is_active ? 0 : 1;
    const updatedMembers = teamMembers.map((m) =>
      m.id === member.id ? { ...m, is_active: newStatus } : m
    );
    setTeamMembers(updatedMembers);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${TEAM_URL}/active`,
        {
          id: member.id,
          is_active: newStatus,
          event_id: eventId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update team member.');
      }
    } catch (error) {
      alert(t('Error') + ': ' + error.message);
      // Revert the change in case of error
      setTeamMembers((prevMembers) =>
        prevMembers.map((m) =>
          m.id === member.id ? { ...m, is_active: member.is_active } : m
        )
      );
    }
  };

  return (
    <div className="team-members-page">
      {isLoading ? (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>{t('Profile')}</th>
              <th>{t('Name')}</th>
              <th>{t('Username')}</th>
              <th>{t('Role')}</th>
              <th>{t('Active')}</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.map((member) => (
              <tr key={member.id}>
                <td>
                  <div className="profile-picture-container">
                    <Image
                      src={
                        member.profile_picture
                          ? member.profile_picture
                          : '/default-profile.png'
                      }
                      roundedCircle
                      className="profile-picture"
                    />
                  </div>
                </td>
                <td>{member.name}</td>
                <td>{member.username ? `@${member.username}` : '-'}</td>
                <td>{member.role}</td>
                <td>
                  <Form.Check
                    type="switch"
                    id={`switch-${member.id}`}
                    checked={member.is_active === 1}
                    onChange={() => toggleMemberActive(member)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TeamMembersPage;
