import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FORM_BLOCKS_URL } from '../../constants/Urls';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import './FormBlocksManager.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons

const FormBlocksManager = ({ eventId }) => {
  const [blocks, setBlocks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isTextModalVisible, setIsTextModalVisible] = useState(false);
  const [tempBlockName, setTempBlockName] = useState('');
  const [tempBlockText, setTempBlockText] = useState('');
  const [editingBlock, setEditingBlock] = useState(null);
  const [selectedBlockType, setSelectedBlockType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const blockTypes = [
    'Agenda',
    'Event Team',
    'Contact Point',
    'Guest Checklist',
    'Header',
    'Paragraph',
  ];

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${FORM_BLOCKS_URL}?event_id=${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setBlocks(response.data.blocks);
      } else {
        alert(t('Failed to fetch blocks.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }  
    } finally {
      setIsLoading(false);
    }
  };

  const availableBlockTypes = blockTypes.filter(
    (type) =>
      type === 'Header' ||
      type === 'Paragraph' ||
      !blocks.some((block) => block.block_type === type.toLowerCase().replace(' ', '_'))
  );

  const getBlockLabel = (block) => {
    // Handle block-specific labels and show_when information
    if (block.block_type === 'header' || block.block_type === 'paragraph') {
      return `${block.block_type === 'header' ? t('Header') : t('Paragraph')} ${
        block.show_when !== 'always' ? `(${t(block.show_when.replace('_', ' '))})` : ''
      }`;
    }
    if (block.block_type === 'guest_checklist') {
      return t('Available after guest registration');
    }
    return ''; // For other blocks, no specific label
  };

  const addBlock = (blockType) => {
    setSelectedBlockType(blockType);

    if (blockType === 'Header' || blockType === 'Paragraph') {
      setIsTextModalVisible(true);
      return;
    }

    // For other block types, directly add the block
    addBlockToServer(blockType, blockType);
  };

  const handleAddTextBlock = () => {
    addBlockToServer(selectedBlockType, tempBlockText || selectedBlockType);
    setIsTextModalVisible(false);
    setTempBlockText('');
  };

  const addBlockToServer = async (blockType, title) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${FORM_BLOCKS_URL}`,
        {
          event_id: eventId,
          block_type: blockType.toLowerCase().replace(' ', '_'),
          title: title,
          is_active: 1,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        fetchBlocks();
      } else {
        alert(t('Failed to add block.'));
      }
    } catch (error) {
      alert(t('Failed to add block.'));
    }
  };

  const toggleBlockActive = async (block) => {
    try {
      const token = localStorage.getItem('token');
      const updatedIsActive = block.is_active ? 0 : 1;

      const response = await axios.post(
        `${FORM_BLOCKS_URL}`,
        {
          id: block.id,
          event_id: eventId,
          block_type: block.block_type,
          title: block.title,
          is_active: updatedIsActive,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setBlocks((prevBlocks) =>
          prevBlocks.map((b) =>
            b.id === block.id ? { ...b, is_active: updatedIsActive } : b
          )
        );
      } else {
        alert(t('Failed to update block.'));
      }
    } catch (error) {
      alert(t('Failed to update block.'));
    }
  };

  const editBlockName = (block) => {
    switch (block.block_type) {
      case 'agenda':
        navigate(`/events/${eventId}/agenda`);
        break;
      case 'event_team':
        navigate(`/events/${eventId}/team-members`);
        break;
      case 'registration':
        navigate(`/events/${eventId}/form`);
        break;
      case 'contact_point':
        navigate(`/events/${eventId}/contact-points`);
        break;
      case 'guest_checklist':
        navigate(`/events/${eventId}/guests-checklist`);
        break;
      default:
        setEditingBlock(block);
        setTempBlockName(block.title);
        setIsEditModalVisible(true);
        break;
    }
  };

  const handleSaveBlockName = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${FORM_BLOCKS_URL}`,
        {
          id: editingBlock.id,
          event_id: eventId,
          block_type: editingBlock.block_type,
          title: tempBlockName,
          is_active: editingBlock.is_active,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        fetchBlocks();
        setIsEditModalVisible(false);
      } else {
        alert(t('Failed to update block.'));
      }
    } catch (error) {
      alert(t('Failed to update block.'));
    }
  };

  const confirmDeleteBlock = (block) => {
    if (window.confirm(t('Are you sure you want to delete this block?'))) {
      deleteBlock(block.id);
    }
  };

  const deleteBlock = async (blockId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${FORM_BLOCKS_URL}?id=${blockId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setBlocks(blocks.filter((block) => block.id !== blockId));
      } else {
        alert(t('Failed to delete block.'));
      }
    } catch (error) {
      alert(t('Failed to delete block.'));
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;

    // If dropped outside the list or in the same position, do nothing
    if (!destination || destination.index === source.index) return;

    const reorderedBlocks = Array.from(blocks);
    const [movedBlock] = reorderedBlocks.splice(source.index, 1);
    reorderedBlocks.splice(destination.index, 0, movedBlock);

    // Update the UI immediately with the new order
    setBlocks(reorderedBlocks);

    // Save the new order in the backend
    const token = localStorage.getItem('token');
    try {
      const promises = reorderedBlocks.map((block, index) =>
        axios.post(
          `${FORM_BLOCKS_URL}?updateOrder=true`,
          {
            id: block.id,
            order_id: index,
            event_id: eventId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        )
      );

      await Promise.all(promises);
    } catch (error) {
      alert(t('Failed to update block order.'));
    }
  };

  return (
    <div className="form-blocks-manager">
      <Button
        variant="primary"
        onClick={() => setIsModalVisible(true)}
        disabled={availableBlockTypes.length === 0}
      >
        {t('Add Block')}
      </Button>

      {isLoading ? (
        <div className="loader">{t('Loading...')}</div>
      ) :       (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="blocks">
            {(provided) => (
              <ListGroup className="blocks-list" {...provided.droppableProps} ref={provided.innerRef}>
                {blocks.map((block, index) => (
                  <Draggable key={block.id.toString()} draggableId={block.id.toString()} index={index}>
                    {(provided, snapshot) => (
                    <ListGroup.Item
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`block-item ${snapshot.isDragging ? 'dragging' : ''}`}
                  >
                    <div className="block-details">
                      {/* Display block-specific label ABOVE the block title */}
                      {getBlockLabel(block) && (
                        <div className="block-label">
                          <strong>{t(getBlockLabel(block))}</strong>
                        </div>
                      )}
                      
                      {/* Align drag icon, block title, and actions on the same line */}
                      <div className="block-content">
                        <div className="block-header" {...provided.dragHandleProps}>
                          <i className="fas fa-grip-vertical grip-icon"></i> {/* Drag handle */}
                          <p className="event-block-title">{t(block.title)}</p> {/* Block Title */}
                        </div>
                        
                        <div className="block-actions">
                          <Form.Check
                            type="switch"
                            id={`block-active-switch-${block.id}`}
                            checked={block.is_active === 1}
                            onChange={() => toggleBlockActive(block)}
                          />
                          <Button variant="link" onClick={() => editBlockName(block)}>
                            <i className="fas fa-edit"></i>
                          </Button>
                          {block.block_type !== 'registration' && (
                            <Button variant="link" onClick={() => confirmDeleteBlock(block)}>
                              <i className="fas fa-trash"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                  
                   
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ListGroup>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {/* Modal for selecting block type */}
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Select Block Type')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {availableBlockTypes.map((blockType) => (
            <Button
              key={blockType}
              variant="outline-primary"
              onClick={() => {
                addBlock(blockType);
                setIsModalVisible(false);
              }}
              className="block-type-button"
            >
              {t(blockType)}
            </Button>
          ))}
          {availableBlockTypes.length === 0 && (
            <p>{t('All block types added')}</p>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal for adding Header or Paragraph block */}
      <Modal show={isTextModalVisible} onHide={() => setIsTextModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`Add ${selectedBlockType} Text`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t(`${selectedBlockType} Text`)}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tempBlockText}
              onChange={(e) => setTempBlockText(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsTextModalVisible(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleAddTextBlock}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing block name */}
      <Modal show={isEditModalVisible} onHide={() => setIsEditModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Block Name')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Block Name')}</Form.Label>
            <Form.Control
              type="text"
              value={tempBlockName}
              onChange={(e) => setTempBlockName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsEditModalVisible(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveBlockName}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FormBlocksManager;

