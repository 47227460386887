import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LOGIN_URL } from '../constants/Urls';

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkToken = async () => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const response = await axios.get(LOGIN_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          navigate('/events');
        } else {
          localStorage.removeItem('token');
          navigate('/login-form');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/login-form');
      }
    } else {
      navigate('/login-form');
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return null;
};

export default Login;
