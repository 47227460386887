import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const [privacy, setPrivacy] = useState(null);

  useEffect(() => {
    const loadPrivacyPolicy = async () => {
      const language = i18n.language;
      let privacyData;

      switch (language) {
        case 'ru':
        case 'ru-RU':
          privacyData = await import(`./privacy_ru.json`);
          break;
        default:
          privacyData = await import(`./privacy_en.json`);
          break;
      }

      setPrivacy(privacyData.privacy);
    };

    loadPrivacyPolicy();
  }, [i18n.language]);

  if (!privacy) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className="privacy-container">
      <h1>{privacy.title}</h1>
      {privacy.sections.map((section, index) => (
        <div key={index} className="privacy-section">
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
