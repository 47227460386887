import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { EVENTS_URL } from '../../constants/Urls';
import './EventModal.css';

const EventModal = ({ showModal, onClose, event, onEventCreated, onEventUpdated }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState(25);
  const [currency, setCurrency] = useState('');
  const [budget, setBudget] = useState('');

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setDate(new Date(event.date));
      setTime(parseTime(event.time));
      setLocation(event.location);
      setType(event.type);
      setNumberOfGuests(event.max_guests);
      setCurrency(event.currency);
      setBudget(event.budget);
    } else {
      resetForm();
    }
  }, [event]);

  const resetForm = () => {
    setTitle('');
    setDate(null);
    setTime(null);
    setLocation('');
    setType('');
    setNumberOfGuests(25);
    setCurrency('');
    setBudget('');
  };

  const parseTime = (timeStr) => {
    if (!timeStr) return null;

    // Handle both 12-hour and 24-hour time formats
    let [hour, minute] = timeStr.split(':');
    const meridian = timeStr.includes('AM') || timeStr.includes('PM') ? timeStr.slice(-2) : null;

    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (meridian === 'PM' && hour < 12) {
      hour += 12;
    } else if (meridian === 'AM' && hour === 12) {
      hour = 0;
    }

    if (!isNaN(hour) && !isNaN(minute)) {
      const date = new Date();
      date.setHours(hour, minute, 0, 0);
      return date;
    }
    return null;
  };

  const handleSave = async () => {
    if (!title || !date) {
      alert(`${t('Please fill in')} "${t('Event Name')}" ${t('and')} "${t('Date')}"`);
      return;
    }

    const method = event ? 'PUT' : 'POST';
    const eventId = event ? `?id=${event.id}` : '';

    try {
      const token = localStorage.getItem('token');
      const response = await axios({
        method,
        url: `${EVENTS_URL}${eventId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          title,
          date: date.toISOString().split('T')[0],
          time: time ? time.toTimeString().split(' ')[0].substring(0, 5) : null,
          location,
          type,
          max_guests: Number(numberOfGuests),
          currency,
          budget,
        },
      });

      const updatedEvent = {
        id: event ? event.id : response.data.event_id,
        title,
        date: date.toISOString().split('T')[0],
        time: time ? time.toTimeString().split(' ')[0].substring(0, 5) : null,
        location,
        type,
        max_guests: Number(numberOfGuests),
        currency,
        budget,
      };

      if (event) {
        onEventUpdated(updatedEvent);
      } else {
        onEventCreated(updatedEvent);
      }

      onClose();
    } catch (error) {
      console.error('Error saving event:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{event ? t('Edit Event') : t('Create Event')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t('Event Name')}</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Date')}</Form.Label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="yyyy-MM-dd"
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Time')}</Form.Label>
            <DatePicker
              selected={time}
              onChange={(time) => setTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Location')}</Form.Label>
            <Form.Control
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Number of Guests')}</Form.Label>
            <Form.Control
              type="number"
              rows={3}
              value={numberOfGuests}
              onChange={(e) => setNumberOfGuests(e.target.value)}
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Budget')}</Form.Label>
            <Form.Control
              type="number"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              className="input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Currency')}</Form.Label>
            <Form.Control
              type="text"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className="input"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {event ? t('Update Event') : t('Create Event')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
