import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CONVERSATIONS_URL } from '../../constants/Urls';
import './Conversations.css';

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchConversations = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(CONVERSATIONS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        alert(
          t('Session Expired'),
          t('Your session has expired. Please log in again.')
        );
        navigate('/');
        return;
      }

      if (response.status === 200) {
        setConversations(response.data.conversations);
      } else {
        alert(t('Error'), t('Failed to fetch conversations.'));
      }
    } catch (error) {
      alert(t('Error'), t('Failed to fetch conversations. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
    const intervalId = setInterval(fetchConversations, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const renderItem = (item) => (
    <div
      key={item.id}
      className={`conversation-container ${
        item.unread_messages_count > 0 ? 'unread-conversation' : ''
      }`}
      onClick={() =>
        navigate(`/messages/${item.id}`, {
          state: { conversationId: item.id, userId: item.participant_id },
        })
      }
    >
      <p className="conversation-text">
        {`${item.participant_name} (@${item.participant_username})`}
      </p>
      <p className="message-text">{item.last_message_text}</p>
      <div className="timestamp-container">
        <p className="timestamp-text">
          {new Date(item.updated_at).toLocaleString()}
        </p>
        <i
          className={`fas fa-${item.is_read ? 'check-double' : 'check'}`}
          style={{ color: item.is_read ? '#733BFF' : 'gray' }}
        ></i>
      </div>
      {item.unread_messages_count > 0 && (
        <div className="unread-badge">
          <p className="unread-badge-text">{item.unread_messages_count}</p>
        </div>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <div className="container">
      {conversations.length === 0 ? (
        <p className="empty-text">{t('No conversations available.')}</p>
      ) : (
        <div className="conversations-list">
          {conversations.map((item) => renderItem(item))}
        </div>
      )}
    </div>
  );
};

export default Conversations;
