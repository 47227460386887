import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './DeleteAccountInstruction.css';

const DeleteAccountInstruction = () => {
  const { t, i18n } = useTranslation();
  const [instructions, setInstructions] = useState(null);

  useEffect(() => {
    const loadInstructions = async () => {
      const language = i18n.language;
      let instructionData;

      switch (language) {
        case 'ru':
        case 'ru-RU':
          instructionData = await import(`./delete_account_ru.json`);
          break;
        default:
          instructionData = await import(`./delete_account_en.json`);
          break;
      }

      setInstructions(instructionData.instructions);
    };

    loadInstructions();
  }, [i18n.language]);

  if (!instructions) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className="delete-account-container">
      <h1>{instructions.title}</h1>
      <p>{instructions.description}</p>
      <ul>
        {instructions.steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ul>
      <h2>{instructions.dataTitle}</h2>
      <p>{instructions.dataDetails}</p>
      <h2>{instructions.retentionTitle}</h2>
      <p>{instructions.retentionDetails}</p>
      <p>
        {t('For further assistance, contact us at:')} <a href="mailto:support@givents.by">support@givents.by</a>
      </p>
    </div>
  );
};

export default DeleteAccountInstruction;
