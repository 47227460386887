import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form, Spinner, Card } from 'react-bootstrap';
import { TASKS_URL, TEMPLATE_DETAILS_URL, EVENTS_URL } from '../../constants/Urls';
import TemplateModal from './TemplateModal';
import './ViewTemplate.css';

const ViewTemplate = () => {
  const { id } = useParams();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadTasks();
    fetchEvents();
  }, [id]);

  const loadTasks = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${TEMPLATE_DETAILS_URL}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setTasks(response.data.tasks);
        setTemplateName(response.data.templateName);
      } else {
        setTasks([]);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
        alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setIsLoading(false);
    }
  }, [id, t]);

  const fetchEvents = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(EVENTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setEvents(response.data.map(event => ({
        label: `${event.title} - ${event.date}`,
        value: event.id,
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
      alert(t('Error fetching events') + ': ' + error.message);
    }
  }, [t]);

  const handleAddTaskToEvent = async (task) => {
    if (!selectedEvent) {
      alert(t('Please select an event'));
      return;
    }

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(TASKS_URL, {
        ...task,
        eventId: selectedEvent,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert(t('Task added successfully'));
      } else {
        alert(t('Failed to add task'));
      }
    } catch (error) {
      console.error('Error adding task:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  const handleAddAllTasksToEvent = async () => {
    if (!selectedEvent) {
      alert(t('Please select an event'));
      return;
    }

    const token = localStorage.getItem('token');
    const tasksToAdd = tasks.map(task => ({
      ...task,
      eventId: selectedEvent,
    }));

    try {
      const response = await axios.post(`${TASKS_URL}?fromTemplate=true`, tasksToAdd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert(t('Tasks added successfully'));
      } else {
        alert(t('Failed to add tasks'));
      }
    } catch (error) {
      console.error('Error adding tasks:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedTasks = Array.from(tasks);
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);

    setTasks(reorderedTasks);

    const promises = reorderedTasks.map((task, index) => {
      return axios.put(`${TEMPLATE_DETAILS_URL}?id=${task.id}`, {
        orderId: index,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error('Error saving task order:', error);
      alert(t('Error saving task order') + ': ' + error.message);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return 'success';
      case 'Medium':
        return 'warning';
      case 'High':
        return 'danger';
      case 'Critical':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const handleEditTemplate = () => {
    setShowTemplateModal(true);
  };

  const handleTemplateSaved = () => {
    setShowTemplateModal(false);
    loadTasks(); // reload tasks to reflect changes
  };

  return (
    <Container className="view-template-container">
      <Row className="mb-4">
        <Col>
          <h1>{templateName}</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <Form.Select onChange={(e) => setSelectedEvent(e.target.value)} value={selectedEvent}>
            <option value="">{t('Select an event')}</option>
            {events.map(event => (
              <option key={event.value} value={event.value}>{event.label}</option>
            ))}
          </Form.Select>
        </Col>
        <Col md={6} className="text-end">
        <Button variant="secondary" className="ms-2" onClick={handleEditTemplate}>
            {t('Edit Template')}
          </Button>
          <Button variant="primary" onClick={handleAddAllTasksToEvent}>
            {t('Add All Tasks')}
          </Button>
        </Col>
      </Row>

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>{t('Loading...')}</p>
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tasks">
            {(provided) => (
              <div className="tasks-list" {...provided.droppableProps} ref={provided.innerRef}>
                {tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`task-item ${snapshot.isDragging ? 'dragging' : ''}`}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Title>{task.task_name}</Card.Title>
                            <Button variant="outline-primary" onClick={() => handleAddTaskToEvent(task)}>
                              {task.type === 'section' ? t('Add Section') : t('Add Task')}
                            </Button>
                          </div>
                          {task.type === 'task' && (
                            <>
                              <Card.Text>
                                <strong>{t('Priority')}: </strong>
                                <span className={`text-${getPriorityColor(task.priority)}`}>
                                  {t(task.priority)}
                                </span>
                              </Card.Text>
                              <Card.Text>{task.description}</Card.Text>
                            </>
                          )}
                          <div {...provided.dragHandleProps} className="drag-handle"></div>
                        </Card.Body>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <TemplateModal
        show={showTemplateModal}
        onHide={() => setShowTemplateModal(false)}
        templateId={id}
        onTemplateSaved={handleTemplateSaved}
      />
    </Container>
  );
};

export default ViewTemplate;
