import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Form,
  Card,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TASKS_URL, EVENTS_URL } from '../../constants/Urls';
import NewTaskModal from './NewTaskModal';
import './AllTasks.css';
import { useNavigate } from 'react-router-dom';

const AllTasks = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [expandedTasks, setExpandedTasks] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [groupedTasks, setGroupedTasks] = useState([]);
  const [isAddTaskModalVisible, setAddTaskModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [filtersModalVisible, setFiltersModalVisible] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [filterPriority, setFilterPriority] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterAssignee, setFilterAssignee] = useState('');
  const [filterEvent, setFilterEvent] = useState('');
  const [events, setEvents] = useState([]);
  const [showTodayTasks, setShowTodayTasks] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadTasks();
    loadEvents();
  }, [filterDate, filterPriority, filterStatus, filterAssignee, filterEvent]);

  useEffect(() => {
    if (showTodayTasks) {
      setFilterDate(formatDate(new Date()));
    } else {
      setFilterDate('');
    }
  }, [showTodayTasks]);

  const loadTasks = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      const formattedFilterDate = filterDate ? formatDateForServer(filterDate) : '';
      const url = `${TASKS_URL}?date=${formattedFilterDate}&priority=${filterPriority}&status=${filterStatus}&assignee=${filterAssignee}&eventId=${filterEvent}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        groupTasksByEvent(response.data.tasks);
      } else {
        alert(t('Error') + ': ' + (response.data.error || t('Failed to fetch tasks.')));
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setIsLoading(false);
    }
  };

  const loadEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(EVENTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setEvents(response.data);
      } else {
        alert(t('Error') + ': ' + t('Failed to fetch events.'));
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    }
  };

  const closeAddTaskModal = () => {
    loadTasks();
    setSelectedTask(null);
    setAddTaskModalVisible(false);
  };

  const groupTasksByEvent = (tasks) => {
    const grouped = tasks.map((event) => ({
      eventId: event.event_id,
      title: event.title,
      date: event.date,
      tasks: event.tasks,
    }));
    setGroupedTasks(grouped);

    const initialExpandedState = {};
    grouped.forEach((event) => {
      initialExpandedState[event.event_id] = true;
    });
    setExpandedGroups(initialExpandedState);
  };

  const toggleEventGroup = (eventId) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };

  const handleTaskStatusChange = async (taskId, currentStatus) => {
    const newStatus = currentStatus === 'New' ? 'Done' : 'New';
    const endpoint = `${TASKS_URL}?id=${taskId}`;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        endpoint,
        { status: newStatus },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        loadTasks();
      } else {
        alert(t('Error') + ': ' + (response.data.error || t('Failed to update task status.')));
      }
    } catch (error) {
      alert(t('Error') + ': ' + t('Failed to update task status. Please try again.'));
    }
  };

  const toggleTaskDetails = (taskId) => {
    setExpandedTasks((prevExpandedTasks) => ({
      ...prevExpandedTasks,
      [taskId]: !prevExpandedTasks[taskId],
    }));
  };

  const handleFilterRemove = (filterType) => {
    if (filterType === 'date') {
      setFilterDate('');
      setShowTodayTasks(false);
    } else if (filterType === 'priority') {
      setFilterPriority('');
    } else if (filterType === 'status') {
      setFilterStatus('');
    } else if (filterType === 'assignee') {
      setFilterAssignee('');
    } else if (filterType === 'event') {
      setFilterEvent('');
    }
    loadTasks();
  };

  const formatDateForDisplay = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  };

  const handleTaskEdit = (task) => {
    console.log("Editing task:", task);
    setSelectedTask(task);
    setAddTaskModalVisible(true);
  };

  const renderTaskItem = (item, index) => {
    const dueDate = item.date ? formatDateForDisplay(item.date) : t('None');

    const getPriorityColor = (priority) => {
      switch (priority) {
        case 'Low':
          return 'green';
        case 'Medium':
          return 'orange';
        case 'High':
          return 'red';
        case 'Critical':
          return 'red';
        default:
          return 'black';
      }
    };

    const priorityStyle = {
      color: getPriorityColor(item.priority),
    };

    if (item.type === 'section') {
      return (
        <Card key={index} className="mb-2">
          <Card.Body>
            <Row>
              <Col>
                <h5 style={{ color: item.color }}>{item.task_name}</h5>
              </Col>
              <Col className="text-right">
                <Button variant="outline-primary" onClick={() => handleTaskEdit(item)}>
                  {t('Edit')}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }

    return (
      <Card key={index} className="mb-2">
        <Card.Body>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                checked={item.status === 'Done'}
                onChange={() => handleTaskStatusChange(item.id, item.status)}
                label={
                  <span style={{ textDecoration: item.status === 'Done' ? 'line-through' : 'none' }}>
                    {item.task_name}
                  </span>
                }
              />
            </Col>
            <Col className="text-right">
              <Button variant="outline-primary" onClick={() => handleTaskEdit(item)}>
                {t('Edit')}
              </Button>
            </Col>
          </Row>
          {expandedTasks[item.id] && (
            <div className="mt-3">
              {item.description && (
                <p>
                  <strong>{t('Description')}: </strong>
                  {item.description}
                </p>
              )}
              <p>
                <strong>{t('Status')}: </strong>
                {t(item.status)}
              </p>
              <p>
                <strong>{t('Priority')}: </strong>
                <span style={priorityStyle}>{t(item.priority)}</span>
              </p>
              <p>
                <strong>{t('Due Date')}: </strong>
                {dueDate}
              </p>
              {item.team_member_id && (
                <p>
                  <strong>{t('Assignee')}: </strong>
                  {item.assignee}
                </p>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  const renderEventGroup = (event) => {
    const isExpanded = expandedGroups[event.event_id];
    return (
      <Card key={event.event_id} className="mb-3">
        <Card.Header onClick={() => toggleEventGroup(event.event_id)} style={{ cursor: 'pointer' }}>
          <Row>
            <Col>
              {event.title} - {event.date}
            </Col>
            <Col className="text-right">
              <i className={`fas fa-chevron-${isExpanded ? 'down' : 'right'}`} />
            </Col>
          </Row>
        </Card.Header>
        {isExpanded && (
          <Card.Body>
            {event.tasks.map((task, index) => renderTaskItem(task, index))}
          </Card.Body>
        )}
      </Card>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    } else {
      return groupedTasks.map((event) => renderEventGroup(event));
    }
  };

  const applyFilter = () => {
    loadTasks();
    setFiltersModalVisible(false);
  };

  const handleClearFilter = () => {
    setFilterDate('');
    setFilterPriority('');
    setFilterStatus('');
    setFilterAssignee('');
    setFilterEvent('');
    loadTasks();
  };

  const handleDateChange = (selectedDate) => {
    setFilterDate(formatDate(selectedDate));
  };

  const formatDate = (date) => {
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formatDateForServer = (date) => {
    const [day, month, year] = date.split('.');
    return `${year}-${month}-${day}`;
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <Form.Check
            type="switch"
            id="show-today-tasks"
            label={t("Show Today's Tasks")}
            checked={showTodayTasks}
            onChange={(e) => setShowTodayTasks(e.target.checked)}
          />
        </Col>
        <Col className="text-right">
          <Button variant="outline-primary" onClick={() => setFiltersModalVisible(true)}>
            <i className="fas fa-filter" /> {t('Filters')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>{renderContent()}</Col>
      </Row>
      <Modal show={filtersModalVisible} onHide={() => setFiltersModalVisible(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Filters')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="filterDate">
            <Form.Label>{t('Date')}</Form.Label>
            <Form.Control
              type="date"
              value={filterDate}
              onChange={(e) => handleDateChange(new Date(e.target.value))}
            />
          </Form.Group>
          <Form.Group controlId="filterPriority">
            <Form.Label>{t('Priority')}</Form.Label>
            <Form.Control
              as="select"
              value={filterPriority}
              onChange={(e) => setFilterPriority(e.target.value)}
            >
              <option value="">{t('Select Priority')}</option>
              <option value="Critical">{t('Critical')}</option>
              <option value="High">{t('High')}</option>
              <option value="Medium">{t('Medium')}</option>
              <option value="Low">{t('Low')}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="filterStatus">
            <Form.Label>{t('Status')}</Form.Label>
            <Form.Control
              as="select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">{t('Select Status')}</option>
              <option value="New">{t('New')}</option>
              <option value="In Progress">{t('In Progress')}</option>
              <option value="Done">{t('Done')}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="filterAssignee">
            <Form.Label>{t('Assignee')}</Form.Label>
            <Form.Control
              as="select"
              value={filterAssignee}
              onChange={(e) => setFilterAssignee(e.target.value)}
            >
              <option value="">{t('Select Assignee')}</option>
              {tasks.map((task) => (
                <option key={task.assignee} value={task.assignee}>
                  {task.assignee}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="filterEvent">
            <Form.Label>{t('Event')}</Form.Label>
            <Form.Control
              as="select"
              value={filterEvent}
              onChange={(e) => setFilterEvent(e.target.value)}
            >
              <option value="">{t('Select Event')}</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            {t('Clear Filters')}
          </Button>
          <Button variant="primary" onClick={applyFilter}>
            {t('Apply Filters')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="outline-primary" onClick={() => console.log('Button clicked')}>
        {t('Test Button')}
      </Button>
      <NewTaskModal
        show={isAddTaskModalVisible}
        onHide={closeAddTaskModal}
        task={selectedTask}
      />
    </Container>
  );
};

export default AllTasks;
