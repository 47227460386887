import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap'; // Correct import
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TEMPLATES_URL, EVENTS_URL } from '../../constants/Urls';
import languages from '../../resources/languages.json';

const TemplateModal = ({ show, onHide, templateId, onTemplateSaved }) => {
  const [template, setTemplate] = useState({});
  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // Add state for error message
  const { t, i18n } = useTranslation();
  const isEditMode = !!templateId;

  useEffect(() => {
    if (isEditMode) {
      fetchTemplateDetails();
    } else {
      setDefaultLanguage();
    }
    fetchEvents();
  }, [templateId]);

  const fetchTemplateDetails = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${TEMPLATES_URL}?id=${templateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data;
      setName(data.name);
      setTags(data.tags);
      setDescription(data.description);
      setIsPublic(data.isPublic === 1);
      setLanguage(data.language);
      setTemplate(data);
      setSelectedEvent(data.event_id);
    } catch (error) {
      setErrorMessage(t('Failed to fetch template details.'));
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultLanguage = () => {
    const appLanguage = i18n.language;
    const defaultLanguage = languages.find((lang) => lang.value === appLanguage);
    if (defaultLanguage) {
      setLanguage(defaultLanguage.value);
    } else {
      setLanguage(null);
    }
  };

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(EVENTS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEvents(response.data);
    } catch (error) {
      setErrorMessage(t('Error fetching events'));
    }
  };

  const handleSubmit = async () => {
    if (!selectedEvent) {
      setErrorMessage(`${t('Please select an event')}`);
      return;
    }
    if (!name) {
      setErrorMessage(`${t('Please fill in')} ${t('Template Name')}`);
      return;
    }

    const url = isEditMode ? `${TEMPLATES_URL}?id=${templateId}` : TEMPLATES_URL;
    const method = isEditMode ? 'PUT' : 'POST';
    const body = {
      name,
      description,
      isPublic,
      tags,
      event_id: selectedEvent,
      language,
    };

    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });
      if (response.status === 200 || response.status === 201) {
        onTemplateSaved();
        onHide();
      } else {
        setErrorMessage(t('Failed to save the template. Please try again.'));
      }
    } catch (error) {
      setErrorMessage(t('Failed to submit the template. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const handleDeleteTemplate = async () => {
    if (window.confirm(t('Are you sure you want to delete this template?'))) {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${TEMPLATES_URL}?id=${templateId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          onHide();
          onTemplateSaved();
        } else {
          setErrorMessage(t('Failed to delete the template. Please try again.'));
        }
      } catch (error) {
        setErrorMessage(t('An error occurred while deleting the template. Please try again.'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? t('Update Template') : t('Create a New Template')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {isLoading && <Spinner animation="border" />}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Display error message */}
          {!isEditMode && (
            <Form.Group controlId="formEvent">
              <Form.Label>{t('Select Event')}</Form.Label>
              <Form.Control
                as="select"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
              >
                <option value="">{t('Select Event')}</option>
                {events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="formTemplateName">
            <Form.Label>{t('Template Name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Template Name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formTemplateDescription">
            <Form.Label>{t('Template Description')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder={t('Template Description')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formLanguage">
            <Form.Label>{t('Select Language')}</Form.Label>
            <Form.Control
              as="select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="">{t('Select Language')}</option>
              {languages.map((lang) => (
                <option key={lang.value} value={lang.value}>
                  {t(lang.label)}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formTags">
            <Form.Label>{t('Tags')}</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder={t('Add Tag')}
                value={tagInput}
                onChange={handleTagInputChange}
              />
              <Button variant="primary" onClick={handleAddTag}>
                {t('Add')}
              </Button>
            </div>
            <div className="tags-container">
              {tags.map((tag, index) => (
                <Button
                  key={index}
                  variant="secondary"
                  className="tag-button"
                  onClick={() => setTags(tags.filter((t, i) => i !== index))}
                >
                  {tag} <span className="ml-2">&times;</span>
                </Button>
              ))}
            </div>
          </Form.Group>
          <Form.Group controlId="formPublic">
            <Form.Check
              type="switch"
              label={t('Public')}
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isEditMode && (
          <Button variant="danger" onClick={handleDeleteTemplate}>
            {t('Delete')}
          </Button>
        )}
        <Button variant="secondary" onClick={onHide}>
          {t('Close')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateModal;