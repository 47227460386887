import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { INVITE_FORM_URL } from '../../constants/Urls';
import './FormFieldModal.css';

const FormFieldModal = ({ showModal, onClose, field, eventId }) => {
  const { t } = useTranslation();
  const [fieldLabel, setFieldLabel] = useState('');
  const [fieldType, setFieldType] = useState('text');
  const [options, setOptions] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    if (field) {
      setFieldLabel(field.field_label);
      setFieldType(field.field_type);
      setIsRequired(field.required === 1);
      const initialOptions =
        typeof field.field_options === 'string'
          ? JSON.parse(field.field_options)
          : field.field_options || [];
      setOptions(initialOptions);
    }
  }, [field]);

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleDeleteOption = (index) =>
    setOptions(options.filter((_, i) => i !== index));

  const handleSaveField = async () => {
    const fieldData = {
      event_id: eventId,
      id: field?.id,
      field_label: fieldLabel,
      field_type: fieldType,
      field_options: options,
      required: isRequired ? 1 : 0,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(INVITE_FORM_URL, fieldData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        alert(t('Field saved successfully'));
        onClose();
      } else {
        alert(t('Failed to save field'));
      }
    } catch (error) {
      console.error('Error saving field:', error);
      alert(t('Failed to save field'));
    }
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{field ? t('Edit Field') : t('Add Field')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t('Field Label')}</Form.Label>
            <Form.Control
              type="text"
              value={fieldLabel}
              onChange={(e) => setFieldLabel(e.target.value)}
              placeholder={t('Enter field label')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Field Type')}</Form.Label>
            <Form.Control
              as="select"
              value={fieldType}
              onChange={(e) => setFieldType(e.target.value)}
            >
              <option value="text">{t('Text')}</option>
              <option value="phone">{t('Phone')}</option>
              <option value="email">{t('Email')}</option>
              <option value="checkbox">{t('Checkbox')}</option>
              <option value="radio">{t('Radio')}</option>
            </Form.Control>
          </Form.Group>
          {(fieldType === 'checkbox' || fieldType === 'radio') &&
            options.map((option, index) => (
              <Form.Group key={index} className="option-group">
                <Form.Control
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const updatedOptions = [...options];
                    updatedOptions[index] = e.target.value;
                    setOptions(updatedOptions);
                  }}
                  placeholder={t('Option value')}
                />
                <Button variant="danger" onClick={() => handleDeleteOption(index)}>
                  {t('Delete')}
                </Button>
              </Form.Group>
            ))}
          {(fieldType === 'checkbox' || fieldType === 'radio') && (
            <Button onClick={handleAddOption} className="mb-3">
              {t('Add Option')}
            </Button>
          )}
          <Form.Group>
            <Form.Check
              type="checkbox"
              label={t('Is Required')}
              checked={isRequired}
              onChange={(e) => setIsRequired(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleSaveField}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormFieldModal;
