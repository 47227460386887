import config from '../config.json';

const API_BASE_URL = config.API_BASE_URL;
const UI_BASE_URL = config.UI_BASE_URL;

export const LOGIN_URL = `${API_BASE_URL}/login`;
export const LOGOUT_URL = `${API_BASE_URL}/logout`;
export const EVENTS_URL = `${API_BASE_URL}/events`;
export const REGISTRATION_URL = `${API_BASE_URL}/register`;
export const NOTIFICATIONS_URL = `${API_BASE_URL}/notifications`;
export const NOTIFICATIONS_COUNT_URL = `${API_BASE_URL}/notifications/count`;
export const FORM_URL = `${API_BASE_URL}/form`;
export const INVITE_FORM_URL = `${API_BASE_URL}/inviteForm`;
export const RESPONSES_URL = `${API_BASE_URL}/responses`;
export const TASKS_URL = `${API_BASE_URL}/tasks`;
export const TEAM_URL = `${API_BASE_URL}/team`;
export const TASK_ORDER_URL = `${API_BASE_URL}/taskOrder`;
export const TEMPLATES_URL = `${API_BASE_URL}/templates`;
export const TEMPLATE_LIKES_URL = `${API_BASE_URL}/templateLikes`;
export const TEMPLATE_DETAILS_URL = `${API_BASE_URL}/templateDetails`;
export const PROFILE_URL = `${API_BASE_URL}/profile`;
export const UPLOAD_PROFILE_PICTURE_URL = `${PROFILE_URL}/picture`;
export const INVITATION_URL = `${UI_BASE_URL}/event`;
export const CONVERSATIONS_URL = `${API_BASE_URL}/messages`;
export const MARK_MESSAGE_AS_READ_URL = `${API_BASE_URL}/messages/read`;
export const USERS_URL = `${API_BASE_URL}/users`;
export const CHANGEPASSWORD_URL = `${API_BASE_URL}/changePassword`;
export const FAVORITE_URL = `${API_BASE_URL}/favouriteUsers`;
export const SAVE_DEVICE_TOKEN_URL = `${API_BASE_URL}/save_device_token`;
export const RESET_PASSWORD_URL = `${API_BASE_URL}/resetPassword`;
export const CONTACT_US_URL = `${API_BASE_URL}/contactUs`;
export const CHECKLIST_RESPONSES_URL = `${API_BASE_URL}/guest-checklist-responses`;
export const BANNERS = `${API_BASE_URL}/banners`;
export const PASSWORD_RESET = `${API_BASE_URL}/requestPasswordReset`;
export const COUNTRIES_URL = `${API_BASE_URL}/locations/countries`;
export const CITIES_URL = `${API_BASE_URL}/locations/cities`;
export const SPECIALIZATIONS_URL = `${API_BASE_URL}/specializations`;
export const FORM_BLOCKS_URL = `${API_BASE_URL}/blocks`;
export const AGENDA_URL = `${API_BASE_URL}/agenda`;
export const CONTACT_POINTS_URL = `${API_BASE_URL}/contact-points`;
export const GUEST_CHECKLISTS_URL = `${API_BASE_URL}/checklists`;
export const GUESTS_URL = `${API_BASE_URL}/guests`;
export const PAYMENT_RATES_URL = `${API_BASE_URL}/payment/rates`;
export const PAYMENT_INIT_URL = `${API_BASE_URL}/payment/initiate`;
export const USER_LIMITS_URL = `${API_BASE_URL}/user/limits`;
export const LIMITS_URL = `${API_BASE_URL}/limits`;
