import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AGENDA_URL } from '../../constants/Urls';
import { useParams } from 'react-router-dom';
import './AgendaPage.css';

const AgendaPage = () => {
  const [agendaItems, setAgendaItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [newAgendaItem, setNewAgendaItem] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const { t } = useTranslation();
  const { id: eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAgenda();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgenda = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${AGENDA_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
        },
      });

      if (response.data.success) {
        setAgendaItems(response.data.agenda);
      } else {
        alert(t('Failed to fetch agenda items.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }
    }
  };

  const handleAddItem = () => {
    setEditingItem(null);
    setNewAgendaItem('');
    setStartTime('');
    setEndTime('');
    setShowModal(true);
  };

  const handleSaveItem = async () => {
    if (!newAgendaItem || !startTime || !endTime) {
      alert(t('Please fill in all fields.'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const payload = {
        event_id: eventId,
        agenda_item: newAgendaItem,
        start_time: startTime,
        end_time: endTime,
      };
      if (editingItem) {
        payload.id = editingItem.id;
      }
      const response = await axios.post(`${AGENDA_URL}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        fetchAgenda();
        setShowModal(false);
      } else {
        alert(t('Failed to save agenda item.'));
      }
    } catch (error) {
      alert(t('Failed to save agenda item.'));
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item);
    setNewAgendaItem(item.agenda_item);
    setStartTime(item.start_time.slice(0, 5)); // HH:MM
    setEndTime(item.end_time.slice(0, 5)); // HH:MM
    setShowModal(true);
  };

  const handleDeleteItem = (id) => {
    if (window.confirm(t('Are you sure you want to delete this agenda item?'))) {
      deleteAgendaItem(id);
    }
  };

  const deleteAgendaItem = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${AGENDA_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
        },
      });
      if (response.data.success) {
        fetchAgenda();
      } else {
        alert(t('Failed to delete agenda item.'));
      }
    } catch (error) {
      alert(t('Failed to delete agenda item.'));
    }
  };

  return (
    <div className="agenda-page">
      <Button variant="primary" onClick={handleAddItem}>
        {t('Add Agenda Item')}
      </Button>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>{t('Agenda Item')}</th>
            <th>{t('Start Time')}</th>
            <th>{t('End Time')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {agendaItems.map((item) => (
            <tr key={item.id}>
              <td>{item.agenda_item}</td>
              <td>{item.start_time.slice(0, 5)}</td>
              <td>{item.end_time.slice(0, 5)}</td>
              <td>
                <Button variant="link" onClick={() => handleEditItem(item)}>
                  <i className="fas fa-edit"></i>
                </Button>
                <Button variant="link" onClick={() => handleDeleteItem(item.id)}>
                  <i className="fas fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for adding/editing agenda items */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingItem ? t('Edit Agenda Item') : t('Add Agenda Item')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAgendaItem">
              <Form.Label>{t('Agenda Item')}</Form.Label>
              <Form.Control
                type="text"
                value={newAgendaItem}
                onChange={(e) => setNewAgendaItem(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formStartTime">
              <Form.Label>{t('Start Time')}</Form.Label>
              <Form.Control
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEndTime">
              <Form.Label>{t('End Time')}</Form.Label>
              <Form.Control
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveItem}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgendaPage;
