import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ContactUsModal.css';
import { CONTACT_US_URL } from '../../constants/Urls';

const ContactUsModal = ({ show, handleClose }) => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!email) {
            errors.email = t('Email is required');
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = t('Invalid email address');
        }
        if (!name) {
            errors.name = t('Name is required');
        }
        if (!message) {
            errors.message = t('Message is required');
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            const lang = i18n.language;
            const response = await axios.post(CONTACT_US_URL, { name, email, message, lang });
            setAlert({ type: 'success', message: t(response.data.message) });
            setErrors({});
            handleClose();
        } catch (error) {
            setAlert({ type: 'danger', message: error.response.data.error });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('Contact Us')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
                <Form onSubmit={handleSubmit} noValidate>
                    <Form.Group controlId="name" className="mb-3">
                        <Form.Label>{t('Your Name')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>{t('Email')}</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="message" className="mb-3">
                        <Form.Label>{t('Message')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            isInvalid={!!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">{t('Send Message')}</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ContactUsModal;
