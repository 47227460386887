import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import countriesData from '../../resources/countries.json';
import specializationsData from '../../resources/specializations.json';
import { REGISTRATION_URL } from '../../constants/Urls';
import { useTranslation } from 'react-i18next';
import './RegistrationForm.css';

const RegistrationForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [specializations, setSpecializations] = useState(['']);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCountry) {
      const country = countriesData.find(c => c.name === selectedCountry);
      setCities(country ? country.cities : []);
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  const handleRegister = async () => {
    if (!username || !password || !confirmPassword || !email || !name) {
      alert(t('Please fill in all fields.'));
      return;
    }

    if (password !== confirmPassword) {
      alert(t("Passwords don't match, please try again"));
      return;
    }

    const selectedSpecializations = specializations.filter(spec => spec !== '');
    const lang = i18n.language;

    try {
      const response = await axios.post(REGISTRATION_URL, {
        username,
        password,
        email,
        name,
        country: selectedCountry,
        city: selectedCity,
        is_specialist: isSpecialist ? 1 : 0,
        specializations: selectedSpecializations,
        lang,
      });

      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        alert(t('User has been created!'));
        navigate('/edit-profile', { state: { fromRegistration: true } });
      } else {
        alert(t(response.data.message));
      }
    } catch (error) {
      alert(t('Network Error') + ': ' + error.toString());
    }
  };

  const addSpecializationField = () => {
    setSpecializations([...specializations, '']);
  };

  const removeSpecializationField = index => {
    setSpecializations(specializations.filter((_, i) => i !== index));
  };

  const updateSpecialization = (value, index) => {
    const updatedSpecializations = specializations.map((spec, i) =>
      i === index ? value : spec,
    );
    setSpecializations(updatedSpecializations);
  };

  return (
    <div className="registration-container">
      <div className="registration-box">
        <h2>{t('Register for the Application')}</h2>
        <input
          className="input"
          placeholder={t('Username')}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          className="input"
          placeholder={t('Name')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          className="input"
          placeholder={t('Email')}
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
        />
        <input
          className="input"
          placeholder={t('Password')}
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <input
          className="input"
          placeholder={t('Confirm Password')}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          type="password"
        />
        <button className="register-button" onClick={handleRegister}>
          {t('Register')}
        </button>
        <button className="login-button" onClick={() => navigate('/login')}>
          {t('Already Registered?')}
        </button>
      </div>
    </div>
  );
};

export default RegistrationForm;
