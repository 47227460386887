import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { EVENTS_URL } from '../../constants/Urls';
import EventModal from './EventModal';
import './Events.css';

const Events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadEventsFromServer();
  }, []);

  const loadEventsFromServer = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(EVENTS_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setUpcomingEvents(response.data);
      } else {
        setUpcomingEvents([]);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
        alert(t('Error') + ': ' + error.message);
        setIsLoading(false);
      }   
    }
  };

  const handleEventClick = (event) => {
    navigate(`/events/${event.id}`);
  };

  const handleAddEvent = () => {
    setShowModal(true);
  };

  const handleEventCreated = (newEvent) => {
    setUpcomingEvents([...upcomingEvents, newEvent]);
    setShowModal(false);
  };

  return (
    <div className="event-view-container">
      <h2>{t('Select Event')}</h2>
      <Button variant="primary" onClick={handleAddEvent} className='add-event-btn'>
        {t('Add Event')}
      </Button>
      {isLoading ? (
        <div className="loader">{t('Loading...')}</div>
      ) : (
        <div className="events-list">
          {upcomingEvents.length === 0 ? (
            <div>{t('No upcoming events')}</div>
          ) : (
            upcomingEvents.map((event, index) => (
              <div key={index} className="event-item" onClick={() => handleEventClick(event)}>
                <h3>{event.title}</h3>
                <p>{`${t('Date')}: ${event.date}`}</p>
                <p>{`${t('Time')}: ${event.time}`}</p>
                <p>{`${t('Type')}: ${event.type}`}</p>
                <p>{`${t('Owner')}: ${event.name}`}</p>
              </div>
            ))
          )}
        </div>
      )}
      {showModal && (
        <EventModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onEventCreated={handleEventCreated}
        />
      )}
    </div>
  );
};

export default Events;
