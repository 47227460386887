let currentScreen = null;
let isFirstPage = false;

export const setCurrentScreen = (screen) => {
  currentScreen = screen;
};

export const getCurrentScreen = () => {
  return currentScreen;
};

export const setFirstPageState = (state) => {
  isFirstPage = state;
};

export const getFirstPageState = () => {
  return isFirstPage;
};
