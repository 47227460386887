import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import './ResetPassword.css';
import { RESET_PASSWORD_URL } from '../../constants/Urls';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(RESET_PASSWORD_URL, { token, newPassword });
            setMessage(response.data.message);
            setError('');
        } catch (error) {
            setError(error.response.data.error);
            setMessage('');
        }
    };

    return (
        <Container className="reset-password-container">
            <div className="reset-password-form">
                <h2 className="my-4">{t("Reset Password")}</h2>
                {message && <Alert variant="success">{t(message)}</Alert>}
                {error && <Alert variant="danger">{t(error)}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="newPassword" className="mb-3">
                        <Form.Label>{t("New Password")}</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword" className="mb-3">
                        <Form.Label>{t("Confirm Password")}</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">{t("Reset Password")}</Button>
                </Form>
            </div>
        </Container>
    );
};

export default ResetPassword;
