import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { INVITE_FORM_URL, INVITATION_URL } from '../../constants/Urls';
import FormBlocksManager from './FormBlocksManager';
import { useNavigate } from 'react-router-dom';
import './InviteFormPage.css';

const InviteFormPage = () => {
  const { id: eventId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State variables
  const [formName, setFormName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isNameModalVisible, setIsNameModalVisible] = useState(false);
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
  const [tempFormName, setTempFormName] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchFormData(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const fetchFormData = async (eventId) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${INVITE_FORM_URL}?event_id=${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setFormName(response.data.unique_name || '');
        setDescription(response.data.description || '');
        setIsActive(response.data.is_active === 1);
      } else {
        alert(t('Failed to fetch form data.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }  
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (formName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${INVITE_FORM_URL}/metadata`,
        {
          event_id: eventId,
          unique_name: formName,
          description: description,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setFormName(formName);
        alert(t('Form metadata saved successfully.'));
      } else {
        alert(t('Failed to save form metadata.'));
      }
    } catch (error) {
      alert(t('Failed to save form metadata.'));
    }
  };

  const toggleIsActive = async () => {
    const newIsActive = !isActive;
    setIsActive(newIsActive);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${INVITE_FORM_URL}/metadata`,
        {
          event_id: eventId,
          is_active: newIsActive ? 1 : 0,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        alert(
          newIsActive
            ? t('Event registration is activated.')
            : t('Event registration is deactivated.')
        );
      } else {
        alert(t('Failed to update registration status.'));
      }
    } catch (error) {
      alert(t('Failed to update registration status.'));
    }
  };

  const openEditModal = (type) => {
    if (type === 'name') {
      setTempFormName(formName);
      setIsNameModalVisible(true);
    } else {
      setTempDescription(description);
      setIsDescriptionModalVisible(true);
    }
  };

  const handleSaveModal = async (type) => {
    if (type === 'name') {
      await handleSave(tempFormName);
      setIsNameModalVisible(false);
    } else {
      setDescription(tempDescription);
      await handleSave();
      setIsDescriptionModalVisible(false);
    }
  };

  const handleCancelModal = (type) => {
    if (type === 'name') {
      setIsNameModalVisible(false);
    } else {
      setIsDescriptionModalVisible(false);
    }
  };

  const getRegistrationUrl = () => `${INVITATION_URL}/${formName}`;

  const handleShare = () => {
    navigator.clipboard.writeText(getRegistrationUrl()).then(
      () => {
        alert(t('Link copied to clipboard.'));
      },
      () => {
        alert(t('Failed to copy the link.'));
      }
    );
  };

  if (isLoading) {
    return <div className="loader">{t('Loading...')}</div>;
  }

  return (
    <div className="invite-form-page">
      <div className="form-header">
        <h2>{t('Invite Form')}</h2>
        <div className="switch-container">
          <span>{t('Is Active')}</span>
          <Form.Check
            type="switch"
            id="is-active-switch"
            checked={isActive}
            onChange={toggleIsActive}
          />
        </div>
      </div>
      <div className="form-content">
        <div className="form-item">
          <Form.Label>{t('Event URL')}</Form.Label>
          <div className="event-url">
            <Form.Control type="text" value={getRegistrationUrl()} readOnly />
            <Button variant="link" onClick={handleShare}>
              <i className="fas fa-share-alt"></i>
            </Button>
            <Button variant="link" onClick={() => openEditModal('name')}>
              <i className="fas fa-edit"></i>
            </Button>
          </div>
        </div>

        {/* Include FormBlocksManager */}
        <FormBlocksManager eventId={eventId} />
      </div>

      {/* Modal for editing Form Name */}
      <Modal show={isNameModalVisible} onHide={() => handleCancelModal('name')}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Form Name')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Form Name')}</Form.Label>
            <Form.Control
              type="text"
              value={tempFormName}
              onChange={(e) => setTempFormName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancelModal('name')}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={() => handleSaveModal('name')}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing Description */}
      <Modal show={isDescriptionModalVisible} onHide={() => handleCancelModal('description')}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Description')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={tempDescription}
              onChange={(e) => setTempDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancelModal('description')}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={() => handleSaveModal('description')}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InviteFormPage;
