import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './SideBar.css';

const Sidebar = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
      </button>

      <Nav className={`flex-column sidebar ${isOpen ? 'open' : ''}`}>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}`}>{t('Details')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/tasks`}>{t('Tasks')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/team`}>{t('Team')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/guests`}>{t('Guests')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/invite`}>{t('Invite')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/agenda`}>{t('Agenda')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/contact-points`}>{t('Contact Points')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={`/events/${id}/guests-checklist`}>{t('Guest Checklist')}</Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default Sidebar;
