import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TEMPLATES_URL, TEMPLATE_LIKES_URL } from '../../constants/Urls';
import './Templates.css';
import languages from '../../resources/languages.json';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Templates = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPublicSwitchValue, setIsPublicSwitchValue] = useState(false);
  const [isFavouritesSwitchValue, setIsFavouritesSwitchValue] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagFilterInput, setTagFilterInput] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);
  const { t, i18n } = useTranslation();
  const PAGE_SIZE = 5;
  const currentPageRef = useRef(1);
  const hasMoreRef = useRef(true);

  useEffect(() => {
    const defaultLanguage =
      languages.find(lang => lang.value === i18n.language) || null;
    setSelectedLanguage(defaultLanguage ? defaultLanguage.value : null);
    setIsLanguageInitialized(true);
  }, [i18n.language]);

  useEffect(() => {
    if (isLanguageInitialized) {
      currentPageRef.current = 1;
      setTemplates([]);
      hasMoreRef.current = true;
      loadTemplates(true);
    }
  }, [
    isPublicSwitchValue,
    isFavouritesSwitchValue,
    selectedTags,
    searchQuery,
    selectedLanguage,
    isLanguageInitialized,
  ]);

  const loadTemplates = async (reset = false) => {
    if (reset) {
      setTemplates([]);
      currentPageRef.current = 1;
      hasMoreRef.current = true;
    }
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const offset = (currentPageRef.current - 1) * PAGE_SIZE;
      const tagsQueryParam =
        selectedTags.length > 0 ? `&tags=${selectedTags.join(',')}` : '';
      const languageQueryParam = selectedLanguage
        ? `&language=${selectedLanguage}`
        : '';
      const response = await axios.get(
        `${TEMPLATES_URL}?offset=${offset}&limit=${PAGE_SIZE}&search=${searchQuery}&templates=${
          !isPublicSwitchValue ? 'all' : 'my'
        }${tagsQueryParam}${languageQueryParam}&favourites=${isFavouritesSwitchValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const data = response.data.records;
        if (reset) {
          setTemplates(data);
        } else {
          setTemplates(prevTemplates => {
            const newTemplates = [...prevTemplates, ...data];
            const uniqueTemplates = newTemplates.filter(
              (template, index, self) =>
                index === self.findIndex(t => t.id === template.id),
            );
            return uniqueTemplates;
          });
        }
        hasMoreRef.current = data.length === PAGE_SIZE;
      } else {
        alert(t('Failed to load templates. Please try again.'));
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (templateId, isUserLiked) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        TEMPLATE_LIKES_URL,
        {
          template_id: templateId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        setTemplates(prevTemplates =>
          prevTemplates.map(template =>
            template.id === templateId
              ? {
                  ...template,
                  isUserLiked: !isUserLiked,
                  like_count: isUserLiked
                    ? template.like_count - 1
                    : template.like_count + 1,
                }
              : template,
          ),
        );
      } else {
        alert(t('Failed to like template. Please try again.'));
      }
    } catch (error) {
      alert(t('Error') + ': ' + error.message);
    }
  };

  const handleSearch = () => {
    currentPageRef.current = 1;
    setTemplates([]);
    hasMoreRef.current = true;
    loadTemplates(true);
    setShowFiltersModal(false);
  };

  const resetFilters = () => {
    setIsPublicSwitchValue(false);
    setIsFavouritesSwitchValue(false);
    setSelectedTags([]);
    setTagFilterInput('');
    const defaultLanguage =
      languages.find(lang => lang.value === i18n.language) || null;
    setSelectedLanguage(defaultLanguage ? defaultLanguage.value : null);
  };

  const handleLoadMore = () => {
    if (hasMoreRef.current) {
      currentPageRef.current += 1;
      loadTemplates();
    }
  };

  const handleTagFilterInputChange = text => {
    setTagFilterInput(text);
  };

  const handleAddTagFilter = () => {
    if (tagFilterInput.trim() !== '') {
      setSelectedTags([...selectedTags, tagFilterInput.trim()]);
      setTagFilterInput('');
    }
  };

  return (
    <div className="templates-container">
      <div className="filters-container">
        <input
          type="text"
          placeholder={t('Enter Event Type or Name')}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && handleSearch()}
        />
        <button onClick={() => setShowFiltersModal(true)}>
          {t('Filters')}
        </button>
      </div>
      <div className="selected-filters">
        {isPublicSwitchValue && (
          <span className="filter-tag" onClick={() => setIsPublicSwitchValue(false)}>
            {t('Show My Templates')} ×
          </span>
        )}
        {isFavouritesSwitchValue && (
          <span className="filter-tag" onClick={() => setIsFavouritesSwitchValue(false)}>
            {t('Show Favourites')} ×
          </span>
        )}
        {selectedTags.map((tag, index) => (
          <span key={index} className="filter-tag" onClick={() => {
            const updatedTags = [...selectedTags];
            updatedTags.splice(index, 1);
            setSelectedTags(updatedTags);
          }}>
            {tag} ×
          </span>
        ))}
        {selectedLanguage && (
          <span className="filter-tag" onClick={() => setSelectedLanguage(null)}>
            {t('Template Language')}: {t(languages.find(lang => lang.value === selectedLanguage)?.label)} ×
          </span>
        )}
      </div>
      <Modal show={showFiltersModal} onHide={() => setShowFiltersModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Filters')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('Show My Templates')}</Form.Label>
              <Form.Check
                type="switch"
                checked={isPublicSwitchValue}
                onChange={() => setIsPublicSwitchValue(!isPublicSwitchValue)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Show Favourites')}</Form.Label>
              <Form.Check
                type="switch"
                checked={isFavouritesSwitchValue}
                onChange={() => setIsFavouritesSwitchValue(!isFavouritesSwitchValue)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Template Language')}</Form.Label>
              <Form.Control
                as="select"
                value={selectedLanguage}
                onChange={e => setSelectedLanguage(e.target.value)}
              >
                <option value="">{t('Select Language')}</option>
                {languages.map(lang => (
                  <option key={lang.value} value={lang.value}>
                    {t(lang.label)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Add Tag Filter')}</Form.Label>
              <div className="tag-filter-input">
                <Form.Control
                  type="text"
                  value={tagFilterInput}
                  onChange={e => handleTagFilterInputChange(e.target.value)}
                />
                <Button onClick={handleAddTagFilter}>{t('Add')}</Button>
              </div>
            </Form.Group>
            <div className="selected-tags">
              {selectedTags.map((tag, index) => (
                <span key={index} className="filter-tag">
                  {tag}
                  <button
                    onClick={() => {
                      const updatedTags = [...selectedTags];
                      updatedTags.splice(index, 1);
                      setSelectedTags(updatedTags);
                    }}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
            <Button onClick={handleSearch} className="apply-filters-button">
              {t('Apply Filters')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="templates-list">
        {loading ? (
          <div className="loader">{t('Loading...')}</div>
        ) : (
          templates.map(template => (
            <div
              key={template.id}
              className="template-item"
              onClick={() => navigate(`/view-template/${template.id}`)}
              onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
              onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
              <div className="template-details">
                <h3>{template.name}</h3>
                <p>{t('Author')}: @{template.username}</p>
              </div>
              <div className="template-actions">
                <button
                  onClick={e => {
                    e.stopPropagation();
                    handleLike(template.id, template.isUserLiked);
                  }}
                >
                  <i className={`fa${template.isUserLiked ? 's' : 'r'} fa-heart`}></i>
                  {template.like_count}
                </button>
              </div>
            </div>
          ))
        )}
        {hasMoreRef.current && !loading && (
          <div className="load-more">
            <button onClick={handleLoadMore}>{t('Load More')}</button>
          </div>
        )}
        {!loading && templates.length === 0 && (
          <div className="no-templates">{t('No templates available.')}</div>
        )}
      </div>
    </div>
  );
};

export default Templates;
