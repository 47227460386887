import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  NOTIFICATIONS_COUNT_URL,
  NOTIFICATIONS_URL,
} from '../../constants/Urls';
import './Notifications.css';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUnauthorizedAccess = () => {
    alert(t('Session Expired'), t('Please log in again.'));
    navigate('/login-form');
  };

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(NOTIFICATIONS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setNotifications(response.data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
        alert(t('Error'), t('An error occurred while fetching notifications.'));
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(NOTIFICATIONS_COUNT_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setNotificationCount(response.data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
        alert(
          t('Error'),
          t('An error occurred while fetching notification count.')
        );
      }
    } catch (error) {
      alert(t('Error fetching notification count:'), error.toString());
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchNotificationCount();
    const intervalId = setInterval(() => {
      fetchNotifications();
      fetchNotificationCount();
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const markNotificationAsSeen = async (notificationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        NOTIFICATIONS_COUNT_URL,
        { id: notificationId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        fetchNotifications();
        fetchNotificationCount();
      } else {
        alert(t('Failed to mark notification as seen:'), response.status);
      }
    } catch (error) {
      alert(t('Failed to mark notification as seen:'), error);
    }
  };

  const markAllNotificationsAsSeen = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        NOTIFICATIONS_COUNT_URL,
        { markAll: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        fetchNotifications();
        fetchNotificationCount();
      } else {
        alert(t('Failed to mark all notifications as seen:'), response.status);
      }
    } catch (error) {
      alert(t('Failed to mark all notifications as seen:'), error);
    }
  };

  const handleStatusChange = async (id, statusText) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${NOTIFICATIONS_URL}?id=${id}`,
        { status: statusText, seen: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        fetchNotifications();
        fetchNotificationCount();
      } else {
        alert(t('Failed to update status of notification:'), response.status);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    }
  };

  const handleNotificationPress = (notificationId) => {
    markNotificationAsSeen(notificationId);
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return t('Pending');
      case 1:
        return t('Accepted');
      case 2:
        return t('Denied');
      default:
        return null;
    }
  };

  const buildMessage = (item) => {
    if (item.type === 'team_request') {
      return `${item.created_at}:\n${t('You are invited to')} ${
        item.title
      } ${t('by user')} ${item.created_user_username}`;
    } else {
      return item.message;
    }
  };

  return (
    <div className="notifications-container">
      <button
        className="mark-all-read-button"
        onClick={markAllNotificationsAsSeen}
      >
        {t('Mark All as Read')}
      </button>
      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : (
        <div className="notifications-list">
          {notifications.length === 0 ? (
            <p>{t('No notifications to display')}</p>
          ) : (
            notifications.map((item) => (
              <div
                key={item.id}
                className={`notification-item ${
                  item.seen === 1 ? '' : 'unread'
                }`}
                onClick={() => handleNotificationPress(item.id)}
              >
                <p className="notification-text">{buildMessage(item)}</p>
                <div className="status-container">
                  {item.request_id && item.status === 0 ? (
                    <div className="icon-container">
                      <button
                        className="status-icon-button"
                        onClick={() => handleStatusChange(item.id, 1)}
                      >
                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                      </button>
                      <button
                        className="status-icon-button"
                        onClick={() => handleStatusChange(item.id, 2)}
                      >
                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                      </button>
                    </div>
                  ) : (
                    item.request_id && (
                      <p
                        className={`status-text ${
                          item.status === 2 ? 'status-red' : 'status-green'
                        }`}
                      >
                        {getStatus(item.status)}
                      </p>
                    )
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
