import React from 'react';
import { useTranslation } from 'react-i18next';
import './Language.css';

const Language = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    if (window.confirm(t('Are you sure you want to change the language?'))) {
      i18n.changeLanguage(language);
    }
  };

  return (
    <div className="language-container">
      <div className="item" onClick={() => changeLanguage('en')}>
        <span className="text">English</span>
      </div>
      <div className="item" onClick={() => changeLanguage('ru')}>
        <span className="text">Русский</span>
      </div>
    </div>
  );
};

export default Language;
