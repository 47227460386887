import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import axios from 'axios';
import { TEAM_URL, TASKS_URL } from '../../constants/Urls';
import { useTranslation } from 'react-i18next';

const BudgetPieChart = ({ event }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const colors = ['#33cc99', '#AB8BFC']; // Colors for "Rest" and "Spent"

  useEffect(() => {
    const fetchBudgetData = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem('token');

        // Fetch tasks
        const tasksResponse = await axios.get(`${TASKS_URL}?id=${event.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const tasks = tasksResponse.data.tasks || [];
        
        // Fetch team members
        const teamResponse = await axios.get(`${TEAM_URL}?id=${event.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const teamMembers = teamResponse.data.team_members || [];
        const teamFees = teamMembers.reduce((sum, member) => sum + (member.fee || 0), 0);

        // Calculate total spent (done tasks + team fees)
        const doneAmount = tasks
          .filter(task => task.status === 'Done')
          .reduce((sum, task) => sum + task.amount, 0);
        const totalSpent = doneAmount + teamFees;

        if (event.budget === null) {
          // Budget is not allocated
          setData([]);
        } else {
          const remainingBudget = event.budget - totalSpent;
          if (totalSpent > event.budget) {
            // Overbudget case
            setData([{ name: t('Overbudget'), amount: totalSpent, color: colors[1] }]);
          } else {
            // Normal case with remaining and spent
            setData([
              { name: t('Rest'), amount: remainingBudget, color: colors[0], percent: `${Math.round((remainingBudget / event.budget) * 100)}%` },
              { name: t('Spent'), amount: totalSpent, color: colors[1], percent: `${Math.round((totalSpent / event.budget) * 100)}%` },
            ]);
          }
        }
      } catch (error) {
        console.error('Error fetching budget data', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBudgetData();
  }, [event, t]);

  if (isLoading) {
    return <div>{t('Loading...')}</div>;
  }

  if (data.length === 0 && event.budget === null) {
    // No budget allocated
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>{t('Budget')}</h3>
        <div style={{ marginTop: '10px' }}>
          {t('Budget is not allocated')}
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <div>{t('No budget data available')}</div>;
  }

  // Overbudget case: Display the overbudget text and no chart
  if (data[0].name === t('Overbudget')) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>{t('Budget')}</h3>
        <div style={{ color: 'red', marginTop: '10px' }}>
          {t('Exceeds by')} {data[0].amount - event.budget} {event.currency}
        </div>
      </div>
    );
  }

  // Normal case: Display the pie chart
  return (
    <div style={{ textAlign: 'center'}}>
      <h3>{t('Budget')}</h3>
      <PieChart width={400} height={300} style={{ cursor: 'pointer' }}>
        <Pie data={data} dataKey="amount" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <ul>
        {data.map((entry, index) => (
          <li key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.amount} ({entry.percent})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BudgetPieChart;
