import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PAYMENT_RATES_URL, PAYMENT_INIT_URL, USER_LIMITS_URL, LIMITS_URL } from '../../constants/Urls';
import './UpgradeToPremium.css';

const UpgradeToPremium = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get('user') || '');
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [userLimits, setUserLimits] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPaymentRates();
    fetchUserLimits();
  }, []);

  // Fetch the payment rates
  const fetchPaymentRates = async () => {
    try {
      const response = await fetch(PAYMENT_RATES_URL);
      const data = await response.json();
      const formattedCurrencies = data.currencies.map(currency => ({
        currency: currency.currency,
        amount: (currency.amount / 100).toFixed(2).replace('.', ','),
      }));
      setCurrencies(formattedCurrencies);
      setSelectedCurrency(formattedCurrencies[0]?.currency || '');
    } catch (error) {
      console.error('Failed to fetch payment rates:', error);
    }
  };

  // Fetch user limits
  const fetchUserLimits = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(USER_LIMITS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // Handle unauthorized access
        setIsUnauthorized(true);
        fetchAnonymousLimits();
      } else {
        const data = await response.json();
        setUserLimits(data);

        // If email is not provided, set it from the user limits response
        if (!email && data.email) {
          setEmail(data.email);
        }
      }
    } catch (error) {
      console.error('Failed to fetch user limits:', error);
    }
  };

  // Fetch limits for unauthorized users (e.g., anonymous)
  const fetchAnonymousLimits = async () => {
    try {
      const response = await fetch(LIMITS_URL);
      const data = await response.json();
      setUserLimits(data);
    } catch (error) {
      console.error('Failed to fetch anonymous limits:', error);
    }
  };

  // Handle payment process
  const handlePayment = async () => {
    try {
      // Extract the language code (e.g., "ru" from "ru-RU")
      const languageCode = i18n.language.split('-')[0];
  
      const response = await fetch(PAYMENT_INIT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          currency: selectedCurrency,
          language: languageCode, // Send only the language code
        }),
      });
      const data = await response.json();
      console.log(data);
      window.location.href = data.redirect_url;
    } catch (error) {
      console.error('Payment initiation failed:', error);
    }
  };

  return (
    <div className="upgrade-container">
      <h2>{t('Upgrade to Premium')}</h2>

      {/* Current Limits vs Premium */}
      {userLimits && (
        <div className="limits-comparison">
          <div className="limits-table">
            <div className="limits-row">
              <div className="limits-column"></div>
              <div className="limits-column">
                {t('Basic')}
              </div>
              <div className="limits-column">{t('Premium')}</div>
            </div>
            <div className="limits-row">
              <div className="limits-column">{t('Events')}</div>
              <div className="limits-column">
                {userLimits.events.max}
              </div>
              <div className="limits-column">{t('Unlimited')}</div>
            </div>
            <div className="limits-row">
              <div className="limits-column">{t('Tasks')} ({t('per event')})</div>
              <div className="limits-column">
                {userLimits.tasks.max}
              </div>
              <div className="limits-column">{t('Unlimited')}</div>
            </div>
            <div className="limits-row">
              <div className="limits-column">{t('Guests')} ({t('per event')})</div>
              <div className="limits-column">
                {userLimits.guests.max}
              </div>
              <div className="limits-column">{t('Unlimited')}</div>
            </div>
            <div className="limits-row">
              <div className="limits-column">{t('Team')} ({t('per event')})</div>
              <div className="limits-column">
                {userLimits.teams.max}
              </div>
              <div className="limits-column">{t('Unlimited')}</div>
            </div>
          </div>

          <p className="premium-description">
            {t('Upgrade to Premium and enjoy unlimited events, tasks, guests, and teams per event!')}
          </p>
        </div>
      )}

      {/* Upgrade Form */}
      {(!isUnauthorized || email) && (
        <>
          <div className="upgrade-field">
            <label>{t('Email')}</label>
            <input type="email" value={email} disabled />
          </div>

          <div className="upgrade-field">
            <label>{t('Price')}</label>
            <select
              value={selectedCurrency}
              onChange={e => setSelectedCurrency(e.target.value)}
            >
              {currencies.map((currency, index) => (
                <option key={index} value={currency.currency}>
                  {currency.currency} {currency.amount}
                </option>
              ))}
            </select>
          </div>

          <button className="upgrade-button" onClick={handlePayment}>
            {t('Proceed to Payment')}
          </button>

          {/* Terms and Conditions notice */}
            <p className="terms-notice">
                {t('By purchasing, you confirm that you agree to our')}{' '}
                <Link to="/terms-conditions" className="terms-link">
                {t('Terms and Conditions')}
                </Link>
            </p>
        </>
      )}

      {/* If unauthorized, show pricing information */}
      {isUnauthorized && !email && (
        <div className="pricing-section">
            <h4>{t('Price')}</h4>
            <div className="pricing-table">
            {currencies.map((currency, index) => (
                <div key={index} className="pricing-card">
                <div className="pricing-currency">{currency.currency}</div>
                <div className="pricing-amount">{currency.amount}</div>
                </div>
            ))}
            </div>
        </div>
        )}
    </div>
  );
};

export default UpgradeToPremium;
