import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TEAM_URL } from '../../constants/Urls';
import './Team.css';
import NewTeamMemberModal from './NewTeamMemberModal';

const Team = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMember, setEditMember] = useState(null);
  
  useEffect(() => {
    loadTeamMembers();
  }, [id]);

  const loadTeamMembers = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${TEAM_URL}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setTeamMembers(response.data.team_members);
        setCurrentUserId(response.data.current_user_id);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching team members:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
      setIsLoading(false);
    }
  }, [id, t]);

  const handleEditMember = member => {
    setEditMember(member);
    setShowModal(true);
  };

  const getStatusIcon = status => {
    if (status === 1) {
      return <i className="fas fa-check status-icon status-icon--active" />;
    } else if (status === 0) {
      return <i className="fas fa-clock status-icon status-icon--pending" />;
    } else {
      return <div className="status-icon-container"></div>;
    }
  };

  const handleAddTeamMember = () => {
    setEditMember(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    loadTeamMembers(); // Reload team members after adding or editing a member
  };

  if (isLoading) {
    return <div className="loader">{t('Loading...')}</div>;
  }

  return (
    <div className="team-container">
      <h2>{t('Team Members')}</h2>
      <div className="team-list">
        {teamMembers.length === 0 ? (
          <div>{t('No team members available.')}</div>
        ) : (
          teamMembers.map(member => (
            <div key={member.id} className="team-item">
              <div className="status-icon-container">
                {getStatusIcon(member.status)}
              </div>
              <div className="team-item-details">
                <div>
                  <strong>{t('Name')}:</strong> {member.name}
                </div>
                <div>
                  <strong>{t('Role')}:</strong> {t(member.role || 'None')}
                </div>
              </div>
              {member.username && member.user_id !== currentUserId && (
                <button
                  className="team-item-button"
                  onClick={() => navigate(`/messages/${member.username}`, {
                    state: { username: member.username, userId: member.user_id }})}
                >
                  {t('Message')}
                </button>
              )}
              <button
                className="team-item-button"
                onClick={() => handleEditMember(member)}
              >
                {t('Edit')}
              </button>
            </div>
          ))
        )}
      </div>
      <button className="add-team-member-button" onClick={handleAddTeamMember}>
        {t('New Team Member')}
      </button>
      {showModal && (
        <NewTeamMemberModal showModal={showModal} onClose={handleCloseModal} eventId={id} editMember={editMember} />
      )}
    </div>
  );
};

export default Team;
