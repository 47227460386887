import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  USERS_URL,
  FAVORITE_URL,
  COUNTRIES_URL,
  CITIES_URL,
  SPECIALIZATIONS_URL,
} from '../../constants/Urls';
import './Users.css';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import i18n from 'i18next';

const Users = ({ event }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({
    country: '',
    countryLabel: '',
    city: '',
    cityLabel: '',
    specializations: [],
    isSpecialistOnly: false,
    isFavoritesOnly: false,
  });

  const [countriesData, setCountriesData] = useState([]);
  const [cities, setCities] = useState([]);
  const [specializationsData, setSpecializationsData] = useState([]);

  const [isFilterModalVisible, setFilterModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;
  const currentPageRef = useRef(1);

  useEffect(() => {
    loadUsers(true);
  }, [filter, searchTerm]);

  useEffect(() => {
    getCountries();
    getSpecializations();
  }, []);

  useEffect(() => {
    if (filter.country) {
      getCities(filter.country);
    } else {
      setCities([]);
    }
    setFilter((prev) => ({ ...prev, city: '', cityLabel: '' }));
  }, [filter.country]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      loadUsers(true);
    }, 300); // 300ms delay for debouncing
  
    return () => clearTimeout(debounceTimeout);
  }, [filter, searchTerm]);

  const getCountries = async () => {
    try {
      const res = await axios.get(COUNTRIES_URL, {
        params: { lang: i18n.language },
      });
      setCountriesData(res.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getCities = async (country) => {
    try {
      const res = await axios.get(CITIES_URL, {
        params: { country, lang: i18n.language },
      });
      setCities(res.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const getSpecializations = async () => {
    try {
      const res = await axios.get(SPECIALIZATIONS_URL, {
        params: { lang: i18n.language },
      });
      setSpecializationsData(res.data);
    } catch (error) {
      console.error('Error fetching specializations:', error);
    }
  };

  const loadUsers = async (reset = false) => {
    if (reset) {
      setUsers([]);
      currentPageRef.current = 1;
    }
    setIsLoading(true);

    const params = {
      limit: PAGE_SIZE,
      offset: reset ? 0 : users.length,
      lang: i18n.language,
      country: filter.country,
      city: filter.city,
      search: searchTerm,
      isSpecialist: filter.isSpecialistOnly,
      isFavorite: filter.isFavoritesOnly,
      specializations: filter.specializations.map((spec) => spec.value).join(','),
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(USERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      const data = response.data;
      if (reset) {
        setUsers(data);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...data]);
      }
      setHasMore(data.length === PAGE_SIZE);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
        alert(t('Error') + ': ' + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFavorite = async (userId, isFavorite) => {
    try {
      const method = isFavorite ? 'DELETE' : 'POST';
      const token = localStorage.getItem('token');
      const response = await axios({
        method,
        url: FAVORITE_URL,
        headers: { Authorization: `Bearer ${token}` },
        data: { favorite_user_id: userId },
      });

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, is_favorite: !isFavorite } : user
          )
        );
      } else {
        console.error('Failed to toggle favorite status');
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  const loadMoreUsers = () => {
    if (!isLoading && hasMore) {
      loadUsers();
    }
  };

  const openConversation = (user) => {
    navigate(`/messages/${user.username}`, {
      state: { username: user.username, userId: user.id },
    });
  };

  const applyFilters = () => {
    setFilter(tempFilter);
    setFilterModalVisible(false);
    loadUsers(true);
  };

  const clearFilters = () => {
    setFilter({
      country: '',
      countryLabel: '',
      city: '',
      cityLabel: '',
      specializations: [],
      isSpecialistOnly: false,
      isFavoritesOnly: false,
    });
    setFilterModalVisible(false);
    loadUsers(true);
  };

  const [tempFilter, setTempFilter] = useState({ ...filter });

  const closeFilterModal = () => {
    setTempFilter({ ...filter });
    setFilterModalVisible(false);
  };

  const handleFilterRemove = (filterType, value) => {
    if (filterType === 'country') {
      setFilter((prev) => ({ ...prev, country: '', countryLabel: '', city: '', cityLabel: '' }));
      setTempFilter((prev) => ({ ...prev, country: '', countryLabel: '', city: '', cityLabel: '' }));
    } else if (filterType === 'city') {
      setFilter((prev) => ({ ...prev, city: '', cityLabel: '' }));
      setTempFilter((prev) => ({ ...prev, city: '', cityLabel: '' }));
    } else if (filterType === 'specialization') {
      setFilter((prev) => ({
        ...prev,
        specializations: prev.specializations.filter((spec) => spec.label !== value),
      }));
      setTempFilter((prev) => ({
        ...prev,
        specializations: prev.specializations.filter((spec) => spec.label !== value),
      }));
    } else if (filterType === 'specialistOnly') {
      setFilter((prev) => ({ ...prev, isSpecialistOnly: false }));
      setTempFilter((prev) => ({ ...prev, isSpecialistOnly: false }));
    } else if (filterType === 'favoritesOnly') {
      setFilter((prev) => ({ ...prev, isFavoritesOnly: false }));
      setTempFilter((prev) => ({ ...prev, isFavoritesOnly: false }));
    }
    loadUsers(true);
  };

  const openFilterModal = () => {
    setTempFilter({ ...filter });
    setFilterModalVisible(true);
  };
  

  return (
    <div className="users-container">
      <div className="search-container">
        <input
          type="text"
          placeholder={t('Search by name or username')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && loadUsers(true)}
        />
        <button onClick={() => openFilterModal()}>{t('Filters')}</button>
      </div>
      <div className="selected-filters">
        {filter.countryLabel && (
          <span className="filter-tag" onClick={() => handleFilterRemove('country')}>
            {t('Country')}: {filter.countryLabel} ×
          </span>
        )}
        {filter.cityLabel && (
          <span className="filter-tag" onClick={() => handleFilterRemove('city')}>
            {t('City')}: {filter.cityLabel} ×
          </span>
        )}
        {filter.specializations.map((spec) => (
          <span
            key={spec.value}
            className="filter-tag"
            onClick={() => handleFilterRemove('specialization', spec.label)}
          >
            {t('Specialization')}: {spec.label} ×
          </span>
        ))}

        {filter.isSpecialistOnly && (
          <span className="filter-tag" onClick={() => handleFilterRemove('specialistOnly')}>
            {t('Show Specialists')} ×
          </span>
        )}
        {filter.isFavoritesOnly && (
          <span className="filter-tag" onClick={() => handleFilterRemove('favoritesOnly')}>
            {t('Show Favorites')} ×
          </span>
        )}
      </div>
      <div className="users-list">
        {isLoading ? (
          <div className="loader">{t('Loading...')}</div>
        ) : (
          users.map((user) => (
            <div
              key={user.id}
              className="user-item"
              onClick={() => navigate(`/profile/${user.id}`)}
            >
              <img
                src={user.profile_picture || require('../../assets/default-profile.png')}
                alt="Profile"
                className="profile-picture"
              />
              <div className="user-details">
                <p>{`${user.name} (@${user.username})`}</p>
                <p>
                  {user.country && user.city
                    ? `${user.country}, ${user.city}`
                    : user.country || user.city}
                </p>
                {user.specializations && (
                  <p>
                    {t('Specializations')}: {user.specializations.map((spec) => spec.label).join(', ')}
                  </p>
                )}
              </div>
              <div className="icon-buttons">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFavorite(user.id, user.is_favorite);
                  }}
                  className="favorite-button"
                >
                  <i
                    className={`fa${user.is_favorite ? 's' : 'r'} fa-star`}
                    style={{ color: user.is_favorite ? '#FFD700' : '#555' }}
                  ></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    openConversation(user);
                  }}
                  className="message-button"
                >
                  <i className="fas fa-envelope"></i>
                </button>
              </div>
            </div>
          ))
        )}
        {hasMore && !isLoading && (
          <div className="load-more">
            <button onClick={loadMoreUsers}>{t('Load More')}</button>
          </div>
        )}
        {!isLoading && users.length === 0 && (
          <div className="no-users">{t('No users available.')}</div>
        )}
      </div>
      <Modal show={isFilterModalVisible} onHide={closeFilterModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Filter Users')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('Select Country')}</Form.Label>
              <Form.Control
                as="select"
                value={tempFilter.country}
                onChange={(e) => {
                  const selectedCountry = e.target.value;
                  const countryLabel = countriesData.find((c) => c.value === selectedCountry)?.label || '';
                  setTempFilter((prev) => ({
                    ...prev,
                    country: selectedCountry,
                    countryLabel,
                    city: '',
                    cityLabel: '',
                  }));
                }}
              >
                <option value="">{t('Select a country')}</option>
                {countriesData.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Select City')}</Form.Label>
              <Form.Control
                as="select"
                value={tempFilter.city}
                onChange={(e) => {
                  const selectedCity = e.target.value;
                  const cityLabel = cities.find((c) => c.value === selectedCity)?.label || '';
                  setTempFilter((prev) => ({
                    ...prev,
                    city: selectedCity,
                    cityLabel,
                  }));
                }}
                disabled={!tempFilter.country}
              >
                <option value="">{t('Select a city')}</option>
                {cities.map((city) => (
                  <option key={city.value} value={city.value}>
                    {city.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                label={t('Show Specialists')}
                checked={tempFilter.isSpecialistOnly}
                onChange={(e) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    isSpecialistOnly: e.target.checked,
                    specializations: e.target.checked ? prev.specializations : [],
                  }));
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                label={t('Show Favorites')}
                checked={tempFilter.isFavoritesOnly}
                onChange={(e) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    isFavoritesOnly: e.target.checked,
                  }));
                }}
              />
            </Form.Group>
            {tempFilter.isSpecialistOnly && (
              <>
                <Form.Group>
                  <Form.Label>{t('Select Specializations')}</Form.Label>
                  <Form.Control
                    as="select"
                    multiple
                    value={tempFilter.specializations.map((spec) => spec.value)}
                    onChange={(e) => {
                      const selectedOptions = Array.from(e.target.selectedOptions).map((option) => ({
                        value: option.value,
                        label: option.text,
                      }));
                      setTempFilter((prev) => ({
                        ...prev,
                        specializations: selectedOptions,
                      }));
                    }}
                  >
                    {specializationsData.map((spec) => (
                      <option key={spec.value} value={spec.value}>
                        {spec.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}
            <Button variant="primary" onClick={applyFilters} className="apply-filters-button">
              {t('Apply Filters')}
            </Button>
            <Button variant="danger" onClick={clearFilters} className="clear-filters-button">
              {t('Clear Filters')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Users;
