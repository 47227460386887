import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './LoginForm.css';
import { LOGIN_URL } from '../../constants/Urls';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = async () => {
    try {
      const response = await axios.post(LOGIN_URL, `username=${username}&password=${password}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        navigate('/events');
      } else {
        alert(t('Login failed') + ': ' + response.data.error);
      }
    } catch (error) {
      alert(t('Login failed') + ': ' + error.toString());
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>{t('Login to the Application')}</h2>
        <input
          type="text"
          placeholder={t('Username or Email')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder={t('Password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="login-btn" onClick={handleLogin}>{t('Login')}</button>
        <button className="secondary-btn" onClick={() => navigate('/registration')}>{t('Go to Registration')}</button>
        <button className="link-btn" onClick={() => navigate('/forgot-password')}>{t('Forgot password?')}</button>
      </div>
    </div>
  );
};

export default LoginForm;
