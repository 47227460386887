import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    const loadTerms = async () => {
        const language = i18n.language;
        let termsData;
        console.log(language);
    
        switch (language) {
            case 'ru':
            case 'ru-RU':
                termsData = await import(`./terms_ru.json`);
                break;
            default:
                termsData = await import(`./terms_en.json`);
                break;
        }
    
        setTerms(termsData.terms);
    };    

    loadTerms();
  }, [i18n.language]);

  if (!terms) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className="terms-container">
      <h1>{terms.title}</h1>
      {terms.sections.map((section, index) => (
        <div key={index} className="terms-section">
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;
