import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TASKS_URL, TEAM_URL } from '../../constants/Urls';
import './Tasks.css';
import NewTaskModal from './NewTaskModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TemplateModal from '../templates/TemplateModal';

const Tasks = () => {
    const { id } = useParams();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
    date: '',
    priority: '',
    status: '',
    assignee: '',
    showExpenses: false,
    });
    const [teamMembers, setTeamMembers] = useState([]);
    const { t } = useTranslation();
    const [selectedTask, setSelectedTask] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [hoveredTaskId, setHoveredTaskId] = useState(null);
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const navigate = useNavigate();

  useEffect(() => {
    loadTasks();
    loadTeamMembers();
  }, [id, filters]);

  const loadTasks = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${TASKS_URL}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: filters.date,
          priority: filters.priority,
          status: filters.status,
          assignee: filters.assignee,
          expenses: filters.showExpenses,
        },
      });

      if (response.data) {
        setTasks(response.data.tasks);
      } else {
        setTasks([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
      setIsLoading(false);
    }
  }, [id, filters, t]);

  const loadTeamMembers = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${TEAM_URL}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTeamMembers(response.data.team_members || []);
    } catch (error) {
      console.error('Error fetching team members:', error);
      alert(t('Error fetching team members') + ': ' + error.message);
    }
  }, [id, t]);

  const handleTaskStatusChange = async (taskId, currentStatus) => {
    const newStatus = currentStatus === 'New' ? 'Done' : 'New';
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, status: newStatus } : task
    );
    setTasks(updatedTasks);

    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${TASKS_URL}?id=${taskId}`,
        { status: newStatus },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error updating task status:', error);
      alert(t('Error updating task status') + ': ' + error.message);
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const applyFilter = () => {
    loadTasks();
  };

  const clearFilters = () => {
    setFilters({
      date: '',
      priority: '',
      status: '',
      assignee: '',
      showExpenses: false,
    });
  };

  const getPriorityColor = priority => {
    switch (priority) {
      case 'Low':
        return 'green';
      case 'Medium':
        return 'orange';
      case 'High':
        return 'red';
      case 'Critical':
        return 'red';
      default:
        return 'black';
    }
  };

  const handleAddTaskClick = () => {
    setSelectedTask(null);
    setShowModal(true);
  };

  const handleEditTaskClick = task => {
    setSelectedTask(task);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    loadTasks(); // Reload tasks after adding or editing a task
  };

  const handleDragEnd = async result => {
    if (!result.destination) return;

    const reorderedTasks = Array.from(tasks);
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);

    setTasks(reorderedTasks);
    await saveTaskOrderToDatabase(reorderedTasks);
  };

  const handleCreateTemplateClick = () => {
    setTemplateId(null);
    setShowTemplateModal(true);
  };
  
  const handleTemplateModalClose = () => {
    setShowTemplateModal(false);
  };

  const saveTaskOrderToDatabase = async newOrder => {
    const promises = newOrder.map(async (item, index) => {
      const endpoint = `${TASKS_URL}?id=${item.id}`;

      try {
        const token = localStorage.getItem('token');
        await axios.put(
          endpoint,
          { orderId: index },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        alert(t('Error updating task order') + ': ' + error.message);
      }
    });

    await Promise.all(promises);
  };

  return (
    <div className="tasks-container">
      <div className="filters-container">
        <input
          type="date"
          value={filters.date}
          onChange={e => handleFilterChange('date', e.target.value)}
        />
        <select
          value={filters.priority}
          onChange={e => handleFilterChange('priority', e.target.value)}
        >
          <option value="">{t('Priority')}</option>
          <option value="Critical">{t('Critical')}</option>
          <option value="High">{t('High')}</option>
          <option value="Medium">{t('Medium')}</option>
          <option value="Low">{t('Low')}</option>
        </select>
        <select
          value={filters.status}
          onChange={e => handleFilterChange('status', e.target.value)}
        >
          <option value="">{t('Status')}</option>
          <option value="New">{t('New')}</option>
          <option value="In Progress">{t('In Progress')}</option>
          <option value="Done">{t('Done')}</option>
        </select>
        <select
          value={filters.assignee}
          onChange={e => handleFilterChange('assignee', e.target.value)}
        >
          <option value="">{t('Assignee')}</option>
          {teamMembers.map(member => (
            <option key={member.id} value={member.name}>
              {member.name}
            </option>
          ))}
        </select>
        <button onClick={applyFilter}>{t('Apply Filters')}</button>
        <button onClick={clearFilters}>{t('Clear Filters')}</button>
      </div>
      <button className="add-task-button" onClick={handleAddTaskClick}>
        <i className="fas fa-plus"></i> {t('Add Task')}
      </button>
      <button className="create-template-button" onClick={handleCreateTemplateClick}>
        <i className="fas fa-plus"></i> {t('Create Template')}
      </button>
      {isLoading ? (
        <div className="loader">{t('Loading...')}</div>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tasks">
            {(provided) => (
              <div className="tasks-list" {...provided.droppableProps} ref={provided.innerRef}>
                {tasks.length === 0 ? (
                  <div>{t('No tasks available')}</div>
                ) : (
                  tasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`task-item ${snapshot.isDragging ? 'dragging' : ''} ${hoveredTaskId === task.id ? 'hovered' : ''}`}
                          onMouseEnter={() => setHoveredTaskId(task.id)}
                          onMouseLeave={() => setHoveredTaskId(null)}
                          onClick={() => handleEditTaskClick(task)}
                        >
                          <div className="task-item-header">
                            {task.type !== 'section' && (
                              <input
                                type="checkbox"
                                checked={task.status === 'Done'}
                                onChange={() => handleTaskStatusChange(task.id, task.status)}
                                onClick={e => e.stopPropagation()}
                              />
                            )}
                            <span
                              className="task-item-title"
                              style={{
                                textDecoration:
                                  task.status === 'Done' ? 'line-through' : 'none',
                              }}
                            >
                              {task.task_name}
                            </span>
                            <span {...provided.dragHandleProps} className="drag-handle" onClick={e => e.stopPropagation()}>
                              <i className="fas fa-bars"></i>
                            </span>
                          </div>
                          {task.type !== 'section' && (
                            <div className="task-item-details">
                              <div>{`${t('Priority')}: `}
                                <span style={{ color: getPriorityColor(task.priority) }}>
                                  {t(task.priority)}
                                </span>
                              </div>
                              <div>{`${t('Status')}: ${t(task.status)}`}</div>
                              <div>{`${t('Due Date')}: ${task.date}`}</div>
                              {task.assignee && <div>{`${t('Assignee')}: ${task.assignee}`}</div>}
                              {task.amount && <div>{`${t('Amount')}: ${task.amount}`}</div>}
                              {task.description && <div>{`${t('Description')}: ${task.description}`}</div>}
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {showModal && (
        <NewTaskModal
          showModal={showModal}
          onClose={handleModalClose}
          task={selectedTask}
          eventId={id}
          teamMembers={teamMembers}
        />
      )}
      {showTemplateModal && (
        <TemplateModal
          show={showTemplateModal}
          onHide={handleTemplateModalClose}
          templateId={templateId}
          onUpdateTemplateName={() => {}} // Update the template name as necessary
        />
      )}
    </div>
  );
};

export default Tasks;
