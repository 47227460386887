import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  RESPONSES_URL,
  GUEST_CHECKLISTS_URL,
  CHECKLIST_RESPONSES_URL,
  FORM_URL,
  GUESTS_URL,
} from '../../constants/Urls';
import './GuestModal.css';

const GuestModal = ({ showModal, onClose, guest, eventId, onEdit }) => {
  const { t } = useTranslation();
  const [item, setItem] = useState(guest);
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checklist, setChecklist] = useState([]);
  const [checklistResponses, setChecklistResponses] = useState([]);
  const [eventFields, setEventFields] = useState([]);
  const [attended, setAttended] = useState(guest.is_attended);

  useEffect(() => {
    if (guest) {
      fetchUpdatedGuest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guest]);

  const fetchUpdatedGuest = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      // Fetch guest data
      const guestResponse = await axios.get(`${RESPONSES_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
          participant_id: guest.participant_id,
        },
      });
      if (guestResponse.data.success) {
        const updatedGuest = guestResponse.data.responses[0];
        setItem(updatedGuest);
        setResponses(updatedGuest.responses);
        setAttended(updatedGuest.is_attended);
      }

      // Fetch checklist data
      const checklistResponse = await axios.get(`${GUEST_CHECKLISTS_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
        },
      });
      if (checklistResponse.data.success) {
        setChecklist(checklistResponse.data.checklists);
      }

      // Fetch checklist responses for guest
      const checklistResponsesResponse = await axios.get(
        `${CHECKLIST_RESPONSES_URL}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            event_id: eventId,
            guest_id: guest.participant_id,
          },
        }
      );
      if (checklistResponsesResponse.data.success) {
        setChecklistResponses(checklistResponsesResponse.data.completed_items);
      }

      // Fetch event form data
      const eventFormResponse = await axios.get(`${FORM_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
        },
      });
      if (eventFormResponse.data.success) {
        const registrationBlock = eventFormResponse.data.blocks.find(
          (block) => block.block_type === 'registration'
        );
        if (registrationBlock && registrationBlock.fields) {
          setEventFields(registrationBlock.fields);
        }
      }
    } catch (error) {
      console.error('Error fetching guest data:', error);
      alert(t('Error fetching guest data.'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAttendanceChange = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${GUESTS_URL}/attended`,
        {
          participant_id: item.participant_id,
          is_attended: !attended,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAttended(!attended);
        alert(t('Attendance status updated.'));
      } else {
        alert(t('Failed to update attendance status.'));
      }
    } catch (error) {
      console.error('Error updating attendance status:', error);
      alert(t('Failed to update attendance status.'));
    }
  };

  const confirmDelete = () => {
    if (window.confirm(t('Are you sure you want to delete this person?'))) {
      deletePerson();
    }
  };

  const deletePerson = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${RESPONSES_URL}?event_id=${eventId}&participant_id=${item.participant_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert(t('Person deleted successfully.'));
        onClose();
      } else {
        alert(t('Failed to delete person. Please try again.'));
      }
    } catch (error) {
      alert(t('An error occurred while deleting person. Please try again.'));
    }
  };

  const editPerson = () => {
    onClose();
    if (onEdit) {
      onEdit(item);
    }
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('Guest Details')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="loader">{t('Loading...')}</div>
        ) : (
          <div className="guest-details">
            {/* Name */}
            <div className="response-container">
              <div className="response-label">
                <strong>{t('Name')}:</strong>
              </div>
              <div className="response-value">{item.name}</div>
            </div>

            {/* Email */}
            <div className="response-container">
              <div className="response-label">
                <strong>{t('Email')}:</strong>
              </div>
              <div className="response-value">{item.email}</div>
            </div>

            {/* Parent info if any */}
            {item.isCompanion && (
              <div className="response-container">
                <div className="response-label">
                  <strong>{t('Come With')}:</strong>
                </div>
                <div className="response-value">{item.parentName}</div>
              </div>
            )}

            {/* Event Fields */}
            {eventFields.length > 0 && (
              <div>
                <h5>{t('Event Fields')}</h5>
                {eventFields.map((field) => {
                  const guestResponse = responses.find(
                    (response) => response.form_field_id === field.id
                  );
                  const responseValue = guestResponse ? guestResponse.response : '';
                  return (
                    <div key={field.id} className="response-container">
                      <div className="response-label">
                        <strong>{t(field.field_label)}:</strong>
                      </div>
                      <div className="response-value">
                        {Array.isArray(responseValue)
                          ? responseValue.join(', ')
                          : responseValue || t('No response')}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* Organizer's Note */}
            <div className="organizer-note">
              <h5>{t("Organizer's Note")}</h5>
              <div className="response-value">
                {item.comment || t('No comment provided')}
              </div>
            </div>

            {/* Checklist */}
            {checklist.length > 0 && (
              <div className="checklist-container">
                <h5>{t('Guest Checklist')}</h5>
                {checklist.map((checkItem) => {
                  const isChecked = checklistResponses.some(
                    (response) => response.checklist_item_id === checkItem.id
                  );
                  return (
                    <div key={checkItem.id} className="checklist-item">
                      <i
                        className={`fas fa-${isChecked ? 'check-circle' : 'circle'}`}
                        style={{ color: isChecked ? '#AB8BFC' : '#E5DBFF' }}
                      ></i>
                      <span className="checklist-text">{checkItem.item_text}</span>
                    </div>
                  );
                })}
              </div>
            )}

            {/* Attended Status */}
            <div className="attended-container">
              <Form.Check
                type="switch"
                id="attended-switch"
                label={t('Event Attended')}
                checked={attended === 1 || attended === true}
                onChange={handleAttendanceChange}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={editPerson}>
          {t('Edit Guest')}
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
          {t('Delete Person')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuestModal;
