import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import i18n from 'i18next';

import {
  PROFILE_URL,
  UPLOAD_PROFILE_PICTURE_URL,
  COUNTRIES_URL,
  CITIES_URL,
  SPECIALIZATIONS_URL,
} from '../../constants/Urls';
import './EditProfile.css';

const EditProfile = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedCityName, setSelectedCityName] = useState('');
  const [selectedSpecializationLabels, setSelectedSpecializationLabels] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const fromRegistration = location.state?.fromRegistration === true;

  // Helper function for matching labels
  const findOptionByLabel = (options, label) =>
    options.find(
      (option) =>
        option.label.trim().toLowerCase() === label.trim().toLowerCase()
    );

  useEffect(() => {
    fetchUserData();
    fetchSpecializations(); // Fetch specializations early
  }, []);

  useEffect(() => {
    if (selectedCountryName) {
      fetchCountries();
    }
  }, [selectedCountryName]);

  useEffect(() => {
    if (selectedCountry && selectedCityName) {
      fetchCities(selectedCountry.value);
    }
  }, [selectedCountry, selectedCityName]);

  // New useEffect to set selected specializations when both are available
  useEffect(() => {
    if (selectedSpecializationLabels.length > 0 && specializationOptions.length > 0) {
      setSelectedSpecializations();
    }
  }, [selectedSpecializationLabels, specializationOptions]);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(PROFILE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          lang: i18n.language,
        },
      });
      const data = response.data;
      if (data) {
        setUsername(data.username);
        setEmail(data.email);
        setName(data.name);
        setDescription(data.description);
        setIsSpecialist(data.is_specialist === 1);
        setProfilePicture(data.profile_picture);

        // Store labels to match with options later
        setSelectedCountryName(data.country);
        setSelectedCityName(data.city);
        setSelectedSpecializationLabels(data.specializations || []);

        console.log('User Data:', {
          selectedCountryName: data.country,
          selectedCityName: data.city,
          selectedSpecializationLabels: data.specializations,
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response.status === 401) {
        // Redirect to login if 401
        navigate('/');
      } else {
          alert(t('Error') + ': ' + error.message);
      }  
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(COUNTRIES_URL, {
        params: {
          lang: i18n.language,
        },
      });
      const countryOptions = response.data;
      setCountries(countryOptions);
      console.log('Countries:', countryOptions);

      // Set selected country after fetching
      const countryOption = findOptionByLabel(countryOptions, selectedCountryName);
      if (countryOption) {
        setSelectedCountry(countryOption);
      } else {
        console.warn('Selected country not found:', selectedCountryName);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      alert(t('Error fetching countries.'));
    }
  };

  const fetchCities = async (countryValue) => {
    try {
      const response = await axios.get(CITIES_URL, {
        params: {
          country: countryValue,
          lang: i18n.language,
        },
      });
      const cityOptions = response.data;
      setCities(cityOptions);
      console.log('Cities:', cityOptions);

      // Set selected city after fetching
      const cityOption = findOptionByLabel(cityOptions, selectedCityName);
      if (cityOption) {
        setSelectedCity(cityOption);
      } else {
        console.warn('Selected city not found:', selectedCityName);
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      alert(t('Error fetching cities.'));
    }
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axios.get(SPECIALIZATIONS_URL, {
        params: {
          lang: i18n.language,
        },
      });
      const options = response.data;
      setSpecializationOptions(options);
      console.log('Specializations:', options);
    } catch (error) {
      console.error('Error fetching specializations:', error);
      alert(t('Error fetching specializations.'));
    }
  };

  const setSelectedSpecializations = () => {
    const selectedSpecs = specializationOptions.filter((option) =>
      selectedSpecializationLabels.some(
        (label) =>
          label.trim().toLowerCase() === option.label.trim().toLowerCase()
      )
    );
    setSpecializations(selectedSpecs);
  };

  const handleUpdateProfile = async () => {
    const token = localStorage.getItem('token');

    if ((!fromRegistration && (!username || !name || !email)) || !name) {
      alert(t('Please fill in all required fields.'));
      return;
    }

    try {
      await axios.put(
        PROFILE_URL,
        {
          username,
          email,
          name,
          country: selectedCountry ? selectedCountry.value : null,
          city: selectedCity ? selectedCity.value : null,
          is_specialist: isSpecialist ? 1 : 0,
          specializations: specializations.map((spec) => spec.value),
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(t('Profile updated successfully'));
      if (fromRegistration) {
        navigate('/');
      } else {
        navigate('/profile');
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert(`${t('Failed to update profile:')} ${t(error.response.data.error)}`);
    }
  };

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('profile_picture', file);

      try {
        const response = await axios.post(UPLOAD_PROFILE_PICTURE_URL, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfilePicture(response.data.profile_picture);
        alert(t('Profile picture updated successfully'));
      } catch (error) {
        console.error('Failed to update profile picture:', error);
        alert(
          `${t('Failed to update profile picture:')} ${t(
            error.response.data.error
          )}`
        );
      }
    }
  };

  if (isLoading) {
    return <div className="loader">{t('Loading...')}</div>;
  }

  return (
    <div className="edit-profile-container">
      {fromRegistration && (
        <button onClick={() => navigate('/')} className="skip-button">
          {t('Skip')}
        </button>
      )}
      <div className="edit-profile-header">
        <div className="profile-picture-container">
          <img
            src={
              profilePicture
                ? profilePicture
                : require('../../assets/default-profile.png')
            }
            alt="Profile"
            className="profile-picture"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePictureUpload}
            className="upload-input"
          />
        </div>
      </div>
      <div className="edit-profile-form">
        {!fromRegistration && (
          <>
            <div className="form-group">
              <label>{t('Username')}</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t('Username')}
              />
            </div>
            <div className="form-group">
              <label>{t('Name')}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('Name')}
              />
            </div>
            <div className="form-group">
              <label>{t('Email')}</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('Email')}
              />
            </div>
          </>
        )}
        <div className="form-group">
          <label>{t('Profile description')}</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('Profile description')}
          />
        </div>
        <div className="form-group">
          <label>{t('Country')}</label>
          <Select
            options={countries}
            value={selectedCountry}
            onChange={(selectedOption) => {
              setSelectedCountry(selectedOption);
              setSelectedCity(null); // Reset city when country changes
            }}
            placeholder={t('Select a country')}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div className="form-group">
          <label>{t('City')}</label>
          <Select
            options={cities}
            value={selectedCity}
            onChange={(selectedOption) => setSelectedCity(selectedOption)}
            placeholder={t('Select a city')}
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!selectedCountry}
          />
        </div>
        <div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              className="checkbox"
              checked={isSpecialist}
              onChange={(e) => setIsSpecialist(e.target.checked)}
            />
            {t('Are you a specialist?')}
          </label>
        </div>
        {isSpecialist && (
          <div className="form-group">
            <label>{t('Specializations')}</label>
            <Select
              isMulti
              options={specializationOptions}
              value={specializations}
              onChange={(selectedOptions) =>
                setSpecializations(selectedOptions)
              }
              placeholder={t('Select specializations')}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
        )}
        <button onClick={handleUpdateProfile} className="update-button">
          {t('Update Profile')}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
