import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { INVITE_FORM_URL } from '../../constants/Urls';
import FormFieldModal from './FormFieldModal';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './FormFieldsPage.css';

const FormFieldsPage = () => {
  const { id: eventId } = useParams();
  const [fields, setFields] = useState([]);
  const [description, setDescription] = useState('');
  const [companionsCount, setCompanionsCount] = useState(0);
  const [selectedField, setSelectedField] = useState(null);
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isCompanionsModalOpen, setIsCompanionsModalOpen] = useState(false);
  const [tempDescription, setTempDescription] = useState('');
  const [tempCompanionsCount, setTempCompanionsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFormFields();
  }, [eventId]);

  const fetchFormFields = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${INVITE_FORM_URL}?event_id=${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.success) {
        setFields(response.data.fields);
        setDescription(response.data.description || '');
        setCompanionsCount(response.data.companions_count || 0);
      } else {
        alert(t('Failed to fetch form fields.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }  
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteField = async (fieldId) => {
    if (window.confirm(t('Are you sure you want to delete this field?'))) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${INVITE_FORM_URL}?id=${fieldId}&event_id=${eventId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          alert(t('Field deleted successfully.'));
          fetchFormFields();
        } else {
          alert(t('Failed to delete field.'));
        }
      } catch (error) {
        alert(t('Error deleting field.'));
      }
    }
  };

  const handleOpenFieldModal = (field = null) => {
    setSelectedField(field);
    setIsFieldModalOpen(true);
  };

  const handleCloseFieldModal = () => {
    setIsFieldModalOpen(false);
    setSelectedField(null);
    fetchFormFields(); // Refresh fields after modal close
  };

  const handleOpenDescriptionModal = () => {
    setTempDescription(description);
    setIsDescriptionModalOpen(true);
  };

  const handleOpenCompanionsModal = () => {
    setTempCompanionsCount(companionsCount);
    setIsCompanionsModalOpen(true);
  };

  const handleSaveDescription = async () => {
    await saveMetadata({ description: tempDescription });
    setIsDescriptionModalOpen(false);
  };

  const handleSaveCompanionsCount = async () => {
    await saveMetadata({ companions_count: tempCompanionsCount });
    setIsCompanionsModalOpen(false);
  };

  const saveMetadata = async (updatedFields) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${INVITE_FORM_URL}/metadata`, {
        event_id: eventId,
        ...updatedFields,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setDescription(updatedFields.description ?? description);
        setCompanionsCount(updatedFields.companions_count ?? companionsCount);
        alert(t('Metadata saved successfully.'));
      } else {
        alert(t('Failed to save metadata.'));
      }
    } catch (error) {
      alert(t('Failed to save metadata.'));
    }
  };

  return (
    <div className="form-fields-page">
      {isLoading ? (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className="description-section">
            <h4>{t('Form Description')}</h4>
            <p>{description}</p>
            <Button variant="secondary" onClick={handleOpenDescriptionModal}>
              {t('Edit Description')}
            </Button>
          </div>

          <div className="companions-section">
            <h4>{t('Companions Count')}</h4>
            <p>{companionsCount}</p>
            <Button variant="secondary" onClick={handleOpenCompanionsModal}>
              {t('Edit Companions Count')}
            </Button>
          </div>

          <Table bordered hover>
            <thead>
              <tr>
                <th>{t('Field Label')}</th>
                <th>{t('Field Type')}</th>
                <th>{t('Required')}</th>
                <th>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field) => (
                <tr key={field.id}>
                  <td>{field.field_label}</td>
                  <td>{field.field_type}</td>
                  <td>{field.required === 1 ? t('Yes') : t('No')}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleOpenFieldModal(field)}>
                      {t('Edit')}
                    </Button>{' '}
                    <Button variant="danger" onClick={() => handleDeleteField(field.id)}>
                      {t('Delete')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button variant="primary" onClick={() => handleOpenFieldModal()}>
            {t('Add Field')}
          </Button>
        </>
      )}

      <FormFieldModal
        showModal={isFieldModalOpen}
        onClose={handleCloseFieldModal}
        field={selectedField}
        eventId={eventId}
      />

      {/* Description Edit Modal */}
      <Modal show={isDescriptionModalOpen} onHide={() => setIsDescriptionModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Description')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tempDescription}
              onChange={(e) => setTempDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsDescriptionModalOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveDescription}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Companions Count Edit Modal */}
      <Modal show={isCompanionsModalOpen} onHide={() => setIsCompanionsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Companions Count')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Number of Companions')}</Form.Label>
            <Form.Control
              type="number"
              value={tempCompanionsCount}
              onChange={(e) => setTempCompanionsCount(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsCompanionsModalOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveCompanionsCount}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FormFieldsPage;
