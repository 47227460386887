import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { GUEST_CHECKLISTS_URL } from '../../constants/Urls';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './GuestChecklistPage.css';

const GuestChecklistPage = () => {
  const { id: eventId } = useParams();
  const [checklistItems, setChecklistItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tempItem, setTempItem] = useState({ item_text: '', id: null });
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchChecklistItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const fetchChecklistItems = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${GUEST_CHECKLISTS_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          event_id: eventId,
        },
      });

      if (response.data.success) {
        const sortedItems = response.data.checklists.sort(
          (a, b) => a.order_id - b.order_id
        );
        setChecklistItems(sortedItems);
      } else {
        alert(t('Failed to fetch checklist items.'));
      }
    } catch (error) {
        if (error.response.status === 401) {
            // Redirect to login if 401
            navigate('/');
        } else {
            alert(t('Error') + ': ' + error.message);
        }
    }
  };

  const handleSaveItem = async () => {
    if (!tempItem.item_text) {
      alert(t('Please enter checklist item text.'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const method = editMode ? 'PUT' : 'POST';
      const url = GUEST_CHECKLISTS_URL;

      const payload = {
        event_id: eventId,
        item_text: tempItem.item_text,
      };

      if (editMode) {
        payload.id = tempItem.id;
      }

      const response = await axios({
        method: method,
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      if (response.data.success) {
        setShowModal(false);
        fetchChecklistItems();
        alert(t('Checklist item saved successfully.'));
      } else {
        alert(t('Failed to save checklist item.'));
      }
    } catch (error) {
      alert(t('Failed to save checklist item.'));
    }
  };

  const handleEditItem = (item) => {
    setTempItem(item);
    setEditMode(true);
    setShowModal(true);
  };

  const handleDeleteItem = (itemId) => {
    if (window.confirm(t('Are you sure you want to delete this checklist item?'))) {
      deleteChecklistItem(itemId);
    }
  };

  const deleteChecklistItem = async (itemId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${GUEST_CHECKLISTS_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: itemId,
        },
      });

      if (response.data.success) {
        fetchChecklistItems();
        alert(t('Checklist item deleted successfully.'));
      } else {
        alert(t('Failed to delete checklist item.'));
      }
    } catch (error) {
      alert(t('Failed to delete checklist item.'));
    }
  };

  const handleAddItem = () => {
    setTempItem({ item_text: '', id: null });
    setEditMode(false);
    setShowModal(true);
  };

  // Implement drag-and-drop functionality using react-beautiful-dnd
  // or similar library if needed.

  return (
    <div className="guest-checklist-page">
      <Button variant="primary" onClick={handleAddItem}>
        {t('Add Checklist Item')}
      </Button>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>{t('Checklist Item')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {checklistItems.map((item) => (
            <tr key={item.id}>
              <td>{item.item_text}</td>
              <td>
                <Button variant="link" onClick={() => handleEditItem(item)}>
                  <i className="fas fa-edit"></i>
                </Button>
                <Button variant="link" onClick={() => handleDeleteItem(item.id)}>
                  <i className="fas fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for adding/editing checklist item */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? t('Edit Checklist Item') : t('Add Checklist Item')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="checklistItemText">
            <Form.Label>{t('Checklist Item')}</Form.Label>
            <Form.Control
              type="text"
              value={tempItem.item_text}
              onChange={(e) =>
                setTempItem({ ...tempItem, item_text: e.target.value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveItem}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GuestChecklistPage;
