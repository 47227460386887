import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, Navbar, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TopMenu.css';
import { USER_LIMITS_URL } from '../constants/Urls'; // Import the API URL

const TopMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userLimits, setUserLimits] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(true); // New state to control menu visibility

  useEffect(() => {
    const fetchUserLimits = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(USER_LIMITS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          setShowMenu(false); // Hide the menu if 401 error occurs
          return;
        }
        const data = await response.json();
        setUserLimits(data);
      } catch (error) {
        console.error('Error fetching user limits:', error);
        setShowMenu(false); // Hide the menu if there's an error
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserLimits();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleUpgrade = () => {
    if (userLimits && userLimits.email) {
      window.open(`/upgrade-premium?user=${userLimits.email}`, '_blank');
    }
  };

  // Don't render the menu if showMenu is false
  if (!showMenu) {
    return null;
  }

  return (
    <Navbar bg="light" expand="lg" className="top-menu">
      <Container fluid>
        <Navbar.Brand as={Link} to="/events">{t('Givents')}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {!isLoading && userLimits && userLimits.type === 'basic' && (
              <Button
                className="account-upgrade-button"
                onClick={handleUpgrade}
                variant="outline-primary"
              >
                {t('Upgrade to Premium')}
              </Button>
            )}
            <Nav.Link as={Link} to="/events">{t('Events')}</Nav.Link>
            <Nav.Link as={Link} to="/all-tasks">{t('All Tasks')}</Nav.Link>
            <Nav.Link as={Link} to="/users">{t('Users')}</Nav.Link>
            <Nav.Link as={Link} to="/templates">{t('Templates')}</Nav.Link>
            <Nav.Link as={Link} to="/notifications">{t('Notifications')}</Nav.Link>
            <Nav.Link as={Link} to="/messages">{t('Messages')}</Nav.Link>
            <Nav.Link as={Link} to="/profile">{t('Profile')}</Nav.Link>
            <Nav.Link as={Link} to="/settings">{t('Settings')}</Nav.Link>
            <Button onClick={handleLogout} className="ms-2">{t('Logout')}</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopMenu;
