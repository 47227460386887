import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams, Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AGENDA_URL, CONTACT_POINTS_URL, EVENTS_URL, GUESTS_URL, GUEST_CHECKLISTS_URL, TEAM_URL } from '../../constants/Urls';
import SideBar from '../../components/SideBar';
import Tasks from '../tasks/Tasks';
import Team from '../team/Team';
import Guests from '../guests/Guests';
import InviteFormPage from '../form/InviteFormPage';
import EventModal from './EventModal';
import AgendaPage from '../form/AgendaPage';
import ContactPointsPage from '../form/ContactPointsPage';
import GuestChecklistPage from '../form/GuestChecklistPage';
import FormFieldsPage from '../form/FormFieldsPage';
import TeamMembersPage from '../form/TeamMembersPage';
import TasksPieChart from '../tasks/TasksPieChart';
import BudgetPieChart from '../tasks/BudgetPieChart';
import './EventDetail.css';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${EVENTS_URL}?id=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setEvent(response.data);
        }
      } catch (error) {
        if (error.response.status === 401) {
          // Redirect to login if 401
          navigate('/');
        } else {
          alert(t('Error') + ': ' + error.message);
        }   
      }
    };

    fetchEvent();
  }, [id, t]);

  const handleEventUpdated = (updatedEvent) => {
    setEvent(updatedEvent);
    setShowModal(false);
  };

  if (!event) {
    return <div>{t('Loading...')}</div>;
  }

  const handleDeleteEvent = async () => {
    const confirmMessage = event.isOwner
      ? t('Are you sure you want to delete this event?')
      : t('Are you sure you want to leave this event?');

    if (window.confirm(confirmMessage)) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${EVENTS_URL}?id=${event.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          alert(event.isOwner ? t('Event deleted successfully') : t('You left the event successfully'));
          navigate('/events');
        }
      } catch (error) {
        console.error('Error deleting or leaving event:', error);
        alert(t('Failed to delete/leave the event. Please try again.'));
      }
    }
  };

  return (
    <div className="event-detail-container">
      <SideBar />
      <div className="event-content">
        <div className="event-header">
          <h2>{event.title}</h2>
          <div className="button-container">
            <Button variant="primary" onClick={() => setShowModal(true)} className="event-edit-btn">
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteEvent}
              className="event-delete-btn"
            >
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<EventDetails event={event} />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team-members" element={<TeamMembersPage />} />
          <Route path="/guests" element={<Guests />} />
          <Route path="/invite" element={<InviteFormPage />} />
          <Route path="/form" element={<FormFieldsPage />} />
          <Route path="/agenda" element={<AgendaPage />} />
          <Route path="/contact-points" element={<ContactPointsPage />} />
          <Route path="/guests-checklist" element={<GuestChecklistPage />} />
        </Routes>
      </div>
      {showModal && (
        <EventModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          event={event}
          onEventUpdated={handleEventUpdated}
        />
      )}
    </div>
  );
};

const EventDetails = ({ event }) => {
  const { t } = useTranslation();
  const [registrationsCount, setRegistrationsCount] = useState(null);
  const [maxGuestCount, setMaxGuestCount] = useState(null);
  const [daysUntilEvent, setDaysUntilEvent] = useState(null);
  const [agendaCount, setAgendaCount] = useState(null);
  const [teamCount, setTeamCount] = useState(null);
  const [checklistCount, setChecklistCount] = useState(null);
  const [contactPointsCount, setContactPointsCount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${EVENTS_URL}?id=${event.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setMaxGuestCount(response.data.max_guests);
          fetchRegistrations(response.data.id);
          calculateDaysUntilEvent(response.data.date);
          fetchCounts(response.data.id);
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
        alert(t('Error') + ': ' + error.message);
      }
    };

    fetchEventDetails();
  }, [event, t]);

  const fetchRegistrations = async (eventId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${GUESTS_URL}/count?event_id=${eventId}`, { headers: { Authorization: `Bearer ${token}` } });
      setRegistrationsCount(response.data.guests_count);
      setMaxGuestCount(response.data.max_guests);
    } catch (error) {
      console.error('Failed to fetch registrations', error);
    }
  };

  const fetchCounts = async (eventId) => {
    const token = localStorage.getItem('token');
    try {
      const [agendaRes, teamRes, checklistRes, contactPointsRes] = await Promise.all([
        axios.get(`${AGENDA_URL}/count?event_id=${eventId}`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${TEAM_URL}/count?event_id=${eventId}`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${GUEST_CHECKLISTS_URL}/count?event_id=${eventId}`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${CONTACT_POINTS_URL}/count?event_id=${eventId}`, { headers: { Authorization: `Bearer ${token}` } })
      ]);

      setAgendaCount(agendaRes.data.count);
      setTeamCount(teamRes.data.count);
      setChecklistCount(checklistRes.data.count);
      setContactPointsCount(contactPointsRes.data.count);
    } catch (error) {
      console.error('Failed to fetch counts', error);
    }
  };

  const calculateDaysUntilEvent = (eventDateString) => {
    const eventDate = parseDate(eventDateString);
    const today = new Date();
    const timeDiff = eventDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (isNaN(daysDiff) || daysDiff < 0) {
      setDaysUntilEvent(null);
    } else {
      setDaysUntilEvent(daysDiff);
    }
  };

  const parseDate = eventDateString => {
    const [day, month, year] = eventDateString.split('.').map(Number);
    return new Date(year, month - 1, day);
  };

  return (
    <div className="event-details">
      <div className="detail-item">
        <strong>{t('Date')}:</strong> <span>{event.date}</span>
      </div>
      {event.time && (
        <div className="detail-item">
          <strong>{t('Time')}:</strong> <span>{event.time}</span>
        </div>
      )}
      {event.budget && (
        <div className="detail-item">
          <strong>{t('Budget')}:</strong> <span>{event.budget}</span> <span>{event.currency}</span>
        </div>
      )}
      {event.location && (
        <div className="detail-item">
          <strong>{t('Location')}:</strong> <span>{event.location}</span>
        </div>
      )}
      {/* Clickable counters */}
      <div className="counters-container">
        <div         className="counter-item" onClick={() => navigate(`/events/${event.id}/guests`)}>
          <strong>{t('Guests')}:</strong>
          <span>{registrationsCount !== null ? `${registrationsCount}/${maxGuestCount}` : t('Loading...')}</span>
        </div>
        <div className="counter-item">
          <strong>{t('Days Until Event')}:</strong>
          <span>{daysUntilEvent !== null ? daysUntilEvent : t('Event Happened')}</span>
        </div>
        <div className="counter-item" onClick={() => navigate(`/events/${event.id}/agenda`)}>
          <strong>{t('Agenda')}:</strong>
          <span>{agendaCount !== null ? agendaCount : t('Loading...')}</span>
        </div>
        <div className="counter-item" onClick={() => navigate(`/events/${event.id}/team`)}>
          <strong>{t('Team Members')}:</strong>
          <span>{teamCount !== null ? teamCount : t('Loading...')}</span>
        </div>
        <div className="counter-item" onClick={() => navigate(`/events/${event.id}/guests-checklist`)}>
          <strong>{t('Guest Checklist')}:</strong>
          <span>{checklistCount !== null ? checklistCount : t('Loading...')}</span>
        </div>
        <div className="counter-item" onClick={() => navigate(`/events/${event.id}/contact-points`)}>
          <strong>{t('Contact Points')}:</strong>
          <span>{contactPointsCount !== null ? contactPointsCount : t('Loading...')}</span>
        </div>
      </div>

      {/* Charts */}
      <div className="charts-container">
        <div className="chart" onClick={() => navigate(`/events/${event.id}/tasks`)}>
          <TasksPieChart event={event} />
        </div>
        <div className="chart" onClick={() => navigate(`/events/${event.id}/tasks`)}>
          <BudgetPieChart event={event} />
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
