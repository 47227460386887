import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PROFILE_URL } from '../../constants/Urls';
import countriesData from '../../resources/countries.json';
import specializationsData from '../../resources/specializations.json';
import { useTranslation } from 'react-i18next';
import './ProfileDetails.css';

const ProfileDetails = () => {
  const [description, setDescription] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [specializations, setSpecializations] = useState(['']);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const country = countriesData.find(c => c.name === selectedCountry);
      setCities(country ? country.cities : []);
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(PROFILE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setDescription(data.description);
      setSelectedCountry(data.country);
      setSelectedCity(data.city);
      setIsSpecialist(data.is_specialist === 1);
      setSpecializations(
        data.specializations.length > 0 ? data.specializations : [''],
      );
      setProfilePicture(data.profile_picture);
    } catch (error) {
      alert(t('Error fetching user data:') + ' ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    const token = localStorage.getItem('token');
    const selectedSpecializations = specializations.filter(spec => spec !== '');

    try {
      const response = await axios.put(
        PROFILE_URL,
        {
          country: selectedCountry,
          city: selectedCity,
          is_specialist: isSpecialist ? 1 : 0,
          specializations: selectedSpecializations,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data) {
        alert(t('Profile updated successfully'));
        navigate('/events');
      } else {
        alert(t('Failed to update profile:') + ' ' + response.data.error);
      }
    } catch (error) {
      alert(t('Failed to update profile:') + ' ' + error);
    }
  };

  const handleProfilePictureUpload = async event => {
    const token = localStorage.getItem('token');
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('profile_picture', file);

    try {
      const response = await axios.post(PROFILE_URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setProfilePicture(response.data.profile_picture);
        alert(t('Profile picture updated successfully'));
      } else {
        alert(t('Failed to update profile picture:') + ' ' + response.data.error);
      }
    } catch (error) {
      alert(t('Failed to upload profile picture.'));
    }
  };

  const addSpecializationField = () => {
    setSpecializations([...specializations, '']);
  };

  const removeSpecializationField = index => {
    setSpecializations(specializations.filter((_, i) => i !== index));
  };

  const updateSpecialization = (value, index) => {
    const updatedSpecializations = specializations.map((spec, i) =>
      i === index ? value : spec,
    );
    setSpecializations(updatedSpecializations);
  };

  if (isLoading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <div className="profile-container">
      <input type="file" onChange={handleProfilePictureUpload} />
      {profilePicture && (
        <img
          src={profilePicture}
          alt="Profile"
          className="profile-picture"
        />
      )}
      <textarea
        className="input"
        value={description}
        onChange={e => setDescription(e.target.value)}
        placeholder={t('Fill in information about yourself')}
      />
      <select
        className="input"
        value={selectedCountry}
        onChange={e => setSelectedCountry(e.target.value)}
      >
        <option value="">{t('Select a country')}</option>
        {countriesData.map(country => (
          <option key={country.name} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      <select
        className="input"
        value={selectedCity}
        onChange={e => setSelectedCity(e.target.value)}
        disabled={!selectedCountry}
      >
        <option value="">{t('Select a city')}</option>
        {cities.map(city => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
      <div className="switch-container">
        <label>{t('Are you a specialist?')}</label>
        <input
          type="checkbox"
          checked={isSpecialist}
          onChange={() => setIsSpecialist(!isSpecialist)}
        />
      </div>
      {isSpecialist &&
        specializations.map((specialization, index) => (
          <div key={index} className="specialization-container">
            <select
              className="input"
              value={specialization}
              onChange={e => updateSpecialization(e.target.value, index)}
            >
              <option value="">{t('Select a specialization')}</option>
              {specializationsData.map(spec => (
                <option key={spec} value={spec}>
                  {spec}
                </option>
              ))}
            </select>
            {specializations.length > 1 && (
              <button
                className="remove-button"
                onClick={() => removeSpecializationField(index)}
              >
                -
              </button>
            )}
          </div>
        ))}
      {isSpecialist && (
        <button className="add-button" onClick={addSpecializationField}>
          +
        </button>
      )}
      <button className="update-button" onClick={handleUpdateProfile}>
        {t('Update Profile')}
      </button>
    </div>
  );
};

export default ProfileDetails;
